<template>

  <div class="footer-container row">

    <a class="item inner" href="https://www.instagram.com/skeittivideot.fi/">

      
      <img src="@/assets/Instagram.svg" height="50" alt="Seuraa skeittivideot.fi Instagramissa">
      </a>
    <div class="item">
      <span>Toteuttanut:</span>
      <div class="inner">
      <a href="https://jerrysel.in">
      Jerry Selin
      </a>
      </div>
    </div>
    <div class="item">
    </div>

  </div>

</template>

<script>
export default {
  name: "Footer",
  components: {

  },
  props: {

  },
  methods: {

  },
  created: function () {
  },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  font-size: 17px;
}

.footer-container{
  width: 100%;
  background-color: rgb(233, 201, 173);
  margin-bottom: 0px !important;
  z-index: 100;
  
}

.footer-container >  *{
  vertical-align: bottom;
}

.row > * {
  display: inline-block;
}
.item{
  margin: 10px;
}
.inner{
  height: 50px;
  vertical-align: top;
}
.logo-text {
  position: relative;
  right: 35px;
  margin-right: -50px;
}
.logo-img {

  margin-left: -20px;
}

</style>
