
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminList: [],
    adminVideoList: [],
    user: null,
    titles: [],
  },
  getters: {
    admin(state) {
      if(!state.user || !state.user.uid){
        return false
      }
      for(let admin of state.adminList){
        if(admin.id === state.user.uid){
          return true
        }
      }
        return false;
    },

    user(state){
      if(state.user && state.user.uid){
        
      return state.user
      }
    }

},
  mutations: {
    setAdmins (state, admins){
      state.adminList = admins;
    },
    setUser (state, user){
      state.user = user
    },
    logout (state){
      state.user = null
    },
    addAdminVideo (state, adminVideo){
      let adminVideos = state.adminVideoList
      adminVideos.push(adminVideo)
      state.adminVideoList = adminVideos
    },
    setTitles (state, titles){
      state.titles = titles;
    },
  }
})