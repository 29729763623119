<template>
    <img v-bind:src="'https://img.youtube.com/vi/' + videoId + '/0.jpg'" :alt="alt" />

</template>

<script>


export default {

  name: 'YoutubeThumb',
  props: {
    videoId: String,
    alt: String
  },
}
</script>

<style scoped>

</style>