export default [
  "Ei tiedossa",
  "VX1000",
  "VX2000",
  "VX2100",
  "dvx100",
  "hvx200",
  "pd 150",
  "Puhelin",
  "360° kamera",
  "GoPro",
  "Peruna",
  "Muu kasettivideokamera",
  "Muu DVD-videokamera",
  "Muu järjestelmäkamera",
  "Muu digitaalinen videokamera",
  "Muu"
]
