<template>
  <div>
        <div class="instruction-container container">
      <div class="row pointer" v-on:click="toggleInstructions()">
        <h2>Videon tiedoissa puutteita tai virheitä?</h2>
        <img
          v-if="instructionsOpen"
          class="plus-minus"
          src="https://spottimetta.fi/images/miinus.png"
          height="40"
          width="40"
          title="Sulje ohjelaatikko"
          alt="ohjelaatikon sulkeminen - miinus merkki"
        />
        <img
          v-if="!instructionsOpen"
          class="plus-minus"
          src="https://spottimetta.fi/images/plus.png"
          height="40"
          width="40"
          title="Avaa ohjelaatikko"
          alt="ohjelaatikon avaus - plus merkki"
        />
      </div>
      <transition name="fade">
        <div v-if="instructionsOpen" class="instruction-container-inner">
          <p class="text-same-line">
            Lähetä palautetta tällä lomakkeella.
          </p>
          
          <textarea v-model="feedback" rows="4" placeholder="Palaute"/>
          <button @click="handleSend()">Lähetä</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";


export default {
  name: "FeedbackBox",
  components: {


  },
  props: {
    video: Object
  },
  methods: {
    handleSend(){
      if(this.feedback.length < 10){
        this.$errorToast("Laita vähä enemmän infoo (10 merkkiä)")
        return
      }
      if(this.feedback.length > 2000){
        this.$errorToast("2000 merkkiä on palautteen maksimi raja")
        return
      }
      let data = {
        feedback: this.feedback,
        time: new Date().getTime(),
        title: this.$props.video.title,
        videoId: this.$props.video.videoId
      }
      firebase.firestore()
        .collection("feedback")
        .add(data)
        .then( (docRef) => {
          console.log("Feedback Document written with ID: ", docRef.id);
          this.closeInstructions()
          this.feedback = ""
        })
        .catch( (error) => {
          alert("Palautteen lähetyksessä tapahtui virhe... Laita viesti Instagramissa tai sähköpostilla");
          console.log(error);
        });
    },
    toggleInstructions(){
      this.instructionsOpen = !this.instructionsOpen;
    },
        openInstructions() {
      this.instructionsOpen = true;
    },
    closeInstructions() {
      this.instructionsOpen = false;
    },
  },
  created: function () {

  },
  data() {
    return {
      instructionsOpen: false,
      feedback: ""
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  background-color: rgb(239, 242, 255);
  width: fit-content;
  padding: 20px;
  border-radius: 10px;
  border-color: silver;
  border: 1px solid;
  margin: auto;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.instruction-container-inner {
  margin: 10px;
  text-align: left;
}
.row {
  display: flex;
  justify-content: center;
}
.text-same-line {
  display: inline;
}
.plus-minus {
  margin: auto;
  cursor: pointer;
}
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
textarea {
  resize: none;
  width: -webkit-fill-available;
}
button {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}

label {
  margin-top: 10px !important;
  text-align: left;
}

h2{
  font-size: 28px;
  margin-right: 10px;
}

</style>
