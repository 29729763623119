<template>
  <div>
    <img
      v-if="!$props.item || !$props.item.videoId"
      src="https://firebasestorage.googleapis.com/v0/b/spottimett.appspot.com/o/eikuvaa.png?alt=media&token=e2316014-50fc-4455-8784-43b565de8b77"
      alt="Videota ei löydy"
    />

    <VueVideoWrapper
      ref="player"
      v-if="$props.item && $props.item.videoId"
      @ended="handleEnd"
      :autoplay="autoplay"
      :player="$props.item.provider"
      :videoId="$props.item.videoId"
    />
  </div>
</template>

<script>
import VueVideoWrapper from "vue-video-wrapper";
export default {
  name: "VideoPlayer",
  props: {
    item: Object
  },
  components: { VueVideoWrapper },
  methods: {
    handleEnd() {
      this.$emit("video-ended");
    },
    setAutoplay(){

      const urlParams = new URLSearchParams(window.location.search);
      let autoplay = urlParams.get('autoplay') === "true";
      this.autoplay = autoplay;
      if(autoplay === true && this.$props.item.provider === "youtube"){
        setTimeout(() => {
          document.querySelector("iframe").src += "&autoplay=1"
        }, 1000);
      }
    },
    async setCurrentTime(){
      const urlParams = new URLSearchParams(window.location.search);
      let time = urlParams.get('t')
      if(!time){
        return
      }
      if(this.$props.item.provider === "vimeo"){
        await this.$refs.player.player.setCurrentTime(time)
        await this.$refs.player.player.play()
      }
      else{
        await this.$refs.player.player.seekTo(time)
      }
      

    },
    async getAlmostEnded()  {
      const duration = await this.$refs.player.player.getDuration()
      const currentTime = await this.$refs.player.player.getCurrentTime()
      if(currentTime < (duration - 30)){
        return false
      }
      return true
    },
    async getTime()  {
      return await this.$refs.player.player.getCurrentTime()
   
    }
  },
    watch: {
      item: () => {
        this.setAutoplay();
      }
    }
      // watch it
      ,
  created() {
    this.setAutoplay();
    },
  mounted(){
    this.setCurrentTime();
  },
  data(){
    return {
      autoplay: false
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
