<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "toastify-js/src/toastify.css"
export default {
  name: "App",
  components: {},
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
        this.$workbox.messageSW({ type: "SKIP_WAITING" });
        console.log("Updated pwa");
      });
    }
    this.db = firebase.firestore();
    this.fetchAdmins();
    this.fetchTitles();
  },

  methods: {
    async fetchAdmins() {
      let admins = [];
      let querySnapshot = await this.db.collection("users").get();
      querySnapshot.forEach(function (doc) {
        admins.push(doc.data());
      });
      await this.fetchAdminVideos(admins);

      this.$store.commit("setAdmins", admins);
    },
    async fetchAdminVideos(admins) {
      for (let admin of admins) {
        let adminVideo;
        if (admin.visible && admin.video && admin.video.length > 1) {
          let querySnapshot = await this.db
            .collection("videos")
            .where("videoId", "==", admin.video)
            .get();

          querySnapshot.forEach((doc) => {
            adminVideo = doc.data();
          });
          if (adminVideo) {
            this.$store.commit("addAdminVideo", adminVideo);
          }
        }
      }
    },
    async fetchTitles(){
      let doc = await this.db.collection("search").doc("titles").get();
      let titles = doc.data().list.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
      this.$store.commit("setTitles", titles);
    }
  },
  metaInfo() {
    return {
      title: "Skedeleffakokoelma - Skeittivideot.fi",
      meta: [
        {
          name: "description",
          content:
            "Skeittivideot.fi on suomalaiset skedeleffat kokoava arkisto. Kuka tahansa voi lisätä oman suosikkivideonsa arkistoon. Yhteisöllinen arkisto on toteutettu modernisti, joten hyvien leffojen löytäminen on todella miellyttävää!",
        },
        {
          property: "og:title",
          content: "Skedeleffakokoelma - Skeittivideot.fi",
        },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  transition: all 0.3s ease;
  font-family: Roboto !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* For sticking the footer */
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.pointer {
  cursor: pointer;
}

.noselect,
img,
button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.w3-sidebar{
  z-index: 1000;
}
.no-scroll-x{
  overflow-x: hidden;
}
.mb-50{
  margin-bottom: 50px;
}
</style>
