
export const orderingList = [
  "Arkistointiaika (uusin ensin)",
  "Arkistointiaika (vanhin ensin)",
  "Kuvanlaatu (paras ensin)",
  "Kuvanlaatu (huonoin ensin)",
  "Nimi (nouseva)",
  "Nimi (laskeva)",
  "Pituus (nouseva)",
  "Pituus (laskeva)",
  "Julkaisuvuosi (nouseva)",
  "Julkaisuvuosi (laskeva)"
]


export const orderingToQueryData = {
  "Arkistointiaika (uusin ensin)": ["dateAdded", "desc"],
  "Arkistointiaika (vanhin ensin)": ["dateAdded", "asc"],
  "Kuvanlaatu (paras ensin)": ["quality", "desc"],
  "Kuvanlaatu (huonoin ensin)": ["quality", "asc"],
  "Nimi (nouseva)": ["title", "asc"],
  "Nimi (laskeva)": ["title", "desc"],
  "Pituus (nouseva)": ["duration", "asc"],
  "Pituus (laskeva)": ["duration", "desc"],
  "Julkaisuvuosi (nouseva)": ["year", "asc"],
  "Julkaisuvuosi (laskeva)": ["year", "desc"],
}
