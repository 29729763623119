<template>
  <div class="page">
    <div class="content">
      <Logo />
      <Navigation />
      <div v-if="!admin">
        <h1>Kirjaudu Google tunnuksilla admin paneeliin, tarkista että popup ikkunat ovat sallittuja kirjautumista varten. </h1>
        <p>
          Mikäli ei admin käyttäjä kirjautuu, tallennetaan tästä tieto ja
          kirjataan käyttäjä ulos:)
        </p>
        <button @click="handleLogin">Kirjaudu</button>
      </div>
      <div v-if="admin">
        <h1>Oot kirjautunut sisään</h1>
        <p>
         <b> Huom! Sivun päivitys kirjaa sinut ulos</b>, navigoi käyttäen sivuston yläosan navigointia.
          Näet nyt navigoinnissa myös admin sivut. Mikäli et saa jotain ominaisuutta toimimaan, ota yhteyttä Instagramissa @jerryselin tai jerry.selin@gmail.com
        </p>
        <button @click="handleLogout">{{"Kirjaudu ulos (" + user.email + ")"}}</button>
      </div>
    </div>
    <div style="height:40px;"/>
    <div style="flex-grow:1"></div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import Footer from "../components/Footer.vue";
import Navigation from "../components/Navigation.vue";
import firebase from "firebase/app";
export default {
  name: "AdminLogin",
  components: {
    Logo,
    Footer,
    Navigation
  },
  props: {},
  methods: {
    handleLogin() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // The signed-in user info.
          let user = result.user;
          

          if(!this.amIAdmin(user.uid)){
            this.handleLogout();
            throw {name: "Ei admin", message: "Et oo admini listalla..."};

          }
          this.$store.commit('setUser', user);
          localStorage.setItem("usedToAdmin", true)
        })
        .catch(function (error) {
          let errorMessage = error.message;
          alert(errorMessage);
        });
    },
    amIAdmin(id){
      let adminList = this.$store.state.adminList;
      for(let admin of adminList){
        if(admin.id === id){
          return true
        }
      }
        return false;
    },
    handleLogout(){
      firebase.auth().signOut()
      this.$store.commit('logout');
    }
  },
  created: function () {},
  computed: {
    admin() {
      return this.$store.getters.admin;
    },
    user(){
      return this.$store.getters.user;
    }
  },
  data() {
    return {};
  },
                    metaInfo() {
        return { 
            title: "Kirjaudu - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  ""},
                { property: 'og:title', content: ""},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'noindex'} 
            ]
        }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button{
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  border: none;
  background-color: rgb(75, 174, 255);
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
button:hover{
  background-color: rgb(45, 138, 214);
}
</style>
