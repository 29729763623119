<template>
  <div class="page">
    <Logo />
    <Navigation />
    <h1>{{ "Tarkistamattomat videot" }}</h1>
    <div>

      <div

        class="flex-container wrap"
      >
        <div v-for="video in videos" :key="video.dateAdded + video.title">
          <router-link :to="'/admin/tarkistamattomat/' + video.fbId">

          
          <MainListItem :item="video"  />
          </router-link>
        </div>
        <button v-if="lastVisible" class="get-more-videos" @click="fetchVideos">Hae Lisää videoita</button>
        <p v-if="allFetched" class="all-videos-fetched">Kaikki videot haettu</p>
      </div>
    </div>
    <div style="height:40px;"/>
    <div style="flex-grow:1"></div>
    <Footer />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import MainListItem from "../components/MainListItem.vue";
import Navigation from "../components/Navigation.vue";
import Footer from "../components/Footer.vue";
import firebase from "firebase/app";
export default {
  name: "UncheckedPage",
  components: {
    Logo,
    MainListItem,
    Navigation,
    Footer
  },
  props: {},
  methods: {
    async firstFetchVideos() {
      let ref = this.db
        .collection("uncheckedVideos")
        .orderBy("dateAdded", "desc")
        .limit(5);

      let result = await ref.get();
      this.lastVisible = result.docs[result.docs.length - 1];
      if (result.docs.length) {
        result.docs.forEach((doc) => {
          let docData = doc.data();
          docData.fbId = doc.id;
          this.videos.push(docData);
        });
        if(result.docs.length < 5){
          this.lastVisible = undefined;
          this.allFetched = true;
        }
      } else {
        // All data fetched
        this.lastVisible = undefined;
        this.allFetched = true;
      }
    },
    async fetchVideos() {
      let ref;
      if (!this.lastVisible) {
        return;
      }
      ref = this.db
        .collection("uncheckedVideos")
        .orderBy("dateAdded", "desc")
        .startAfter(this.lastVisible)
        .limit(5);

      let result = await ref.get();
      this.lastVisible = result.docs[result.docs.length - 1];
      if (result.docs.length) {
        result.docs.forEach((doc) => {
          let docData = doc.data();
          docData.fbId = doc.id;
          this.videos.push(docData);
        });
        if(result.docs.length < 5){
          this.lastVisible = undefined;
        }
      } else {
        // All data fetched
        this.lastVisible = undefined;
      }
    },
    handleClick(video) {
      this.$router.push({ path: "/admin/tarkistamattomat/" + video.fbId });
    },
        handleAdminRoute(){
      if(!this.$store.getters.admin){

        if(localStorage.getItem("usedToAdmin")){
          this.$router.replace("/admin")
        }
        else{
          this.$router.replace("/")
        }
        return false
      }
      return true
    },
  },
  created: function () {
    if(!this.handleAdminRoute()){
      return
    }
    this.db = firebase.firestore();
    this.firstFetchVideos();
  },
  data() {
    return {
      videos: [],
      lastVisible: undefined,
      allFetched: false
    };
  },
                    metaInfo() {
        return { 
            title: "Tarkistamattomat videot - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  ""},
                { property: 'og:title', content: ""},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'noindex'} 
            ]
        }
}
};
</script>


<style scoped>
.page-container {
  display: grid;
}
.main {
  display: block;
  margin-bottom: 100px;
}
.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer {
  position: fixed;
  bottom: 0px;
}
.get-more-videos{
  margin-top: 100px;
  height: 200px;
  width: 100%;
  display: block;
  background: cadetblue;
  font-size: 30px;
  color: azure;
  cursor: pointer;
}
.all-videos-fetched{
  height: 50px;
  background: peachpuff;
  width: 100%;
  font-size: 30px;
}
a{
  text-decoration: none;
}
</style>
