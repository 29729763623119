<!-- 
Video item in main page 
-->

<template>
  <li
    class="flex-item noselect"
    :title="$props.item.creator + ' - ' + $props.item.title"
  >
    <div @click="$emit('clicked')">
      <div class="title">
        <h1>{{ $props.item.title }}</h1>
      </div>
      <div>
        <YoutubeThumb
          class="lib-img"
          :videoId="$props.item.videoId"
          :alt="'Rullalautailuvideo ' +  $props.item.title + ' vuodelta ' + $props.item.year"
          v-if="item.provider === 'youtube'"
        />
        <VimeoThumb
          class="lib-img"
          :videoId="$props.item.videoId"
          :alt="'Skedeleffa ' +  $props.item.title + ' vuodelta ' + $props.item.year"
          v-if="item.provider === 'vimeo'"
        />
        <!-- This div is in top of img. Visible only when hover -->
        <div class="lib-item-info">
          
          <div v-if="$props.item.camera" class="row-container" title="kamera">
            <img
              src="@/assets/camera-icon.png"
              alt="kamera"
              height="30px"
              width="30px"
            />
            <p title="kamera">{{ $props.item.camera }}</p>
          </div>
          <div v-if="$props.item.skaters" class="row-container" title="skeittarit">
            <img
              src="@/assets/individual.png"
              height="25px"
              alt="Videon skeittarit"
            />
            <p>{{$props.item.skaters.join(", ")}}</p>
            </div>

          <div v-if="$props.item.songs" title="biisit">
            
            <p v-for="song in $props.item.songs" :key="song">♫ {{ song }} ♫</p>
          </div>
          <p title="kuvaus">{{ $props.item.description }}</p>
          <p
            v-if="$props.item.providerDescription"
            v-html="$props.item.providerDescription"
            title="Alkuperäinen kuvaus"
            class="quote"
          >
            
          </p>
        </div>
        <div
          :class="
            'quality ' + ($props.item.provider === 'youtube' ? 'yt' : 'vm')
          "
        >
          <p>{{ getQualityStr() }}</p>
        </div>
        <div
          :class="
            'duration ' + ($props.item.provider === 'youtube' ? 'yt' : 'vm')
          "
        >
          <p>{{ getDurationStr() }}</p>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="tight-row">
            <img
              v-if="$props.item.brand"
              class="row"
              src="@/assets/brand.png"
              height="25px"
              title="Skeittimerkki"
              alt="Skeittimerkki"
            />
            <img
              v-if="!$props.item.brand"
              class="row"
              src="@/assets/individual.png"
              height="25px"
              title="Skeittipossen leffa"
              alt="Yksityinen skeittiporukka"
            />
            <p>{{ $props.item.creator }}</p>
          </div>
          <div class="row-start">
            <p>{{ $props.item.location }}</p>
          </div>
        </div>
        <div class="row">
          <div class="tight-row">
            <p>{{ $props.item.year }}</p>
            <p>→</p>
            <p>
              {{ new Date($props.item.dateAdded).toLocaleDateString("fi") }}
            </p>
          </div>
          <p>{{ $props.item.category }}</p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import YoutubeThumb from "./YoutubeThumb.vue";
import VimeoThumb from "./VimeoThumb.vue";
import { qualityNumToStr } from "../videoQualities.js";

export default {
  name: "MainListItem",
  components: {
    YoutubeThumb,
    VimeoThumb,
  },
  props: {
    item: Object,
  },
  methods: {
    parseVideoId() {
      var regexArr = this.$props.url.match(
        /(videos|video|channels|\.com)\/([\d]+)/
      );
      if (regexArr && regexArr.length > 2) {
        this.videoId = regexArr[2];
      }
    },
    getThumb() {
      if (!this.$props.item) {
        return <p>Loading</p>;
      }
      if (this.$props.item.provider === "youtube") {
        return <p>Youtube</p>;
      } else if (this.$props.item.provider === "vimeo") {
        return <p>Vimeo</p>;
      }
    },
    getDurationStr() {
      let totalSeconds = this.$props.item.duration;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      seconds = String(seconds).padStart(2, "0");
      if (hours > 0) {
        return hours + ":" + minutes + ":" + seconds;
      }
      return minutes + ":" + seconds;
    },
    getQualityStr() {
      if (qualityNumToStr[this.$props.item.quality]) {
        return qualityNumToStr[this.$props.item.quality];
      }
      return this.$props.item.quality + "p";
    },
  },
  created: function () {},
  data() {
    return {
      videoId: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
  background-color: rgb(240, 248, 255);
  border-radius: 15px;
}
li:hover {
  background-color: rgb(226, 241, 255);
}
.title {
  height: 72px;
  margin-top: 0px !important;
}
.lib-img {
  width: 280px;
  height: 157px;
  margin: auto;
  display: block;
  overflow: auto;
  margin: auto;
  z-index: 1;
  object-fit: cover;
}
.lib-item-info {
  position: absolute;
  top: 78px;
  margin: auto;
  left: 5px;
  width: 270px;
  height: 157px;
  background-color: rgba(0, 0, 116, 0.4);
  box-shadow: 0 0 5px 5px rgba(0, 0, 116, 0.4);
  opacity: 0;
  z-index: 10;
}
.lib-item-info * {
  text-align: left;
  color: white;
  margin-left: 5px;
}
.lib-item-info:hover {
  opacity: 1;
}
.flex-item {

  cursor: pointer;
  padding: 5px;
  width: 280px;
  height: 310px;
  margin: 10px;
  text-align: center;
  position: relative;
}
.flex-item * {
  overflow: hidden;
}
h1 {
  margin: 0px;
  font-size: 22px;
  margin-top: 0px;

  height: inherit;
  word-wrap: ellipsis;
}
p {
  margin: 5px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.tight-row {
  display: flex;
  justify-content: center;
}
.duration {
  height: 20px;
  width: 50px;
  border-radius: 5px;
  color: white;
  text-align: center;
  position: absolute;
  top: 210px;
  margin: auto;
  right: 7px;
}
.quality {
  height: 20px;
  width: 50px;
  border-radius: 5px;
  color: white;
  text-align: center;
  position: absolute;
  top: 210px;
  margin: auto;
  left: 7px;
}
.yt {
  background-color: #ff0000;
}
.vm {
  background-color: rgb(0, 173, 239);
}
.duration * {
  margin: auto;
}
.quality * {
  margin: auto;
}
.quote {
  font-style: italic;
}
.row-container * {
  display: inline;
}

</style>
