<template>
  <div class="nav-container noselect">
    <div class="wrap">
      <router-link to="/">
        <div class="box">
          <h1>{{ "Koti" }}</h1>
        </div>
      </router-link>
      <router-link to="/tietoa">
        <div class="box">
          <h1>{{ "Tietoa" }}</h1>
        </div>
      </router-link>
      <router-link to="/lisaavideo">
        <div class="box">
          <h1>{{ "Lisää video" }}</h1>
        </div>
      </router-link>

      <router-link to="/adminit">
        <div class="box">
          <h1>{{ "Ylläpitäjät" }}</h1>
        </div> </router-link>
      
      <router-link v-if="admin || usedToAdmin()" to="/admin">
        <div class="box admin">
          <h1>{{ "Admin" }}</h1>
        </div>
      </router-link>
      <router-link v-if="admin" to="/admin/tarkistamattomat">
        <div class="box admin">
          <h1>{{ "Tarkistamattomat videot" }}</h1>
        </div>
      </router-link>
      <router-link v-if="admin" to="/admin/palaute">
        <div class="box admin">
          <h1>{{ "Palautteet" }}</h1>
        </div>
      </router-link>
      <router-link v-if="admin" to="/admin/tiedot">
        <div class="box admin">
          <h1>{{ "Omat tiedot" }}</h1>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  components: {},
  props: {},
  methods: {
    usedToAdmin(){
      return localStorage.getItem("usedToAdmin")
    }
  },
  created: function () {},
  data() {
    return {};
  },
  computed: {
    admin() {
      return this.$store.getters.admin;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrap * {
  display: inline-block;
}

.box {
  border: 2px solid rgb(75, 174, 255);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
h1{
    font-size: 21px;
}
.box:hover {
  background-color: rgb(183, 223, 255);
  border-color: rgb(6, 90, 158);
  border-radius: 25px;
}
.router-link-exact-active * {
  text-decoration: underline;
}
.admin {
  border: 2px solid rgb(219, 82, 82);
}
.admin:hover {
  background-color: rgb(255, 183, 183);
  border-color: rgb(158, 6, 6);
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 18px;
  }
  .box {
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px 5px;
}
}
</style>
