<template>
  <div class="page-container">
    <SideFiltering @clicked="updateFiltering" />
    <div id="main" class="main">
      <Navigation />
      <div>
        <HorizontalSuggestions
          id="admin-suggestions"
          :videos="$store.state.adminVideoList"
          title="Ylläpitäjien suosikkileffat"
        />
        <ContinueWatching v-if="isContinueWatching()" />
        <h2>Haun tulokset</h2>
        <div
          v-infinite-scroll="fetchMoreVideos"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="200"
          class="flex-container wrap"
        >
          <div
            class="item-wrapper"
            v-for="item in videos"
            :key="item.videoId + item.provider"
          >
            <router-link :to="'videot/' + item.videoId">
              <MainListItem v-bind:item="item" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Footer v-if="topOrBottom" class="footer" />
    </transition>
  </div>
</template>

<script>
import firebase from "firebase/app";

import MainListItem from "../components/MainListItem.vue";
import SideFiltering from "../components/SideFiltering.vue";
import Footer from "../components/Footer.vue";
import ContinueWatching from "../components/continueWatching.vue";
import Navigation from "../components/Navigation.vue";
import animateScrollTo from "animated-scroll-to";
import { orderingToQueryData } from "../orderingList.js";
import HorizontalSuggestions from "../components/HorizontalSuggestions.vue";

export default {
  name: "MainPage",
  components: {
    MainListItem,
    Footer,
    SideFiltering,
    Navigation,
    HorizontalSuggestions,
    ContinueWatching
  },
  props: {
    url: String,
  },
  methods: {
    increment() {
      this.$store.commit("increment");
    },
    isContinueWatching(){
      let item = localStorage.getItem("resumeVideo")
      return item
    },
    async updateFiltering(data) {
      const options = {
        speed: 500,
        minDuration: 250,
        maxDuration: 500,
        element: window,
        cancelOnUserAction: false,
      };

      animateScrollTo(0, options);
      setTimeout(() => {
        this.filterData = data;
        this.initPageVideos();
      }, 500);
    },

    fetchUntilScroll() {
      setTimeout(() => {
        let hasVScroll = document.body.scrollHeight > window.outerHeight;
        if (!hasVScroll) {
          this.fetchMoreVideos();
          this.fetchUntilScroll();
        }
      }, 1000);
    },

    getYears() {
      const currentYear = new Date().getFullYear();
      let years = [];
      const startYear = this.filterData
        ? this.filterData.years[0]
        : currentYear - 9;
      const endYear = this.filterData ? this.filterData.years[1] : currentYear;
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    },
    getComplexQuery() {
      let query = this.db.collection("videos");

      if (this.filterData.limitYears) {
        query = query.where("year", "in", this.getYears());
      }
      if (this.filterData.category !== "Kaikki kategoriat") {
        query = query.where("category", "==", this.filterData.category);
      }
      if (this.filterData.city !== "Kaikki sijainnit") {
        query = query.where("location", "==", this.filterData.city);
      }
      if (this.filterData.creator !== "Kaikki tekijät") {
        query = query.where(
          "creator_low",
          "==",
          this.filterData.creator.toLowerCase()
        );
      }
      if (this.filterData.name !== "Kaikki nimet") {
        query = query.where("names", "array-contains", this.filterData.name);
      }
      if (this.filterData.individuals !== this.filterData.brands) {
        if (this.filterData.individuals) {
          query = query.where("brand", "==", false);
        } else {
          query = query.where("brand", "==", true);
        }
      }
      if (this.filterData.title !== "Kaikki leffojen nimet") {
        query = query.where("title", "==", this.filterData.title);
      } else {
        // Ordering only if title not selected
        const ordering = this.orderingToQueryData[this.filterData.order];
        query = query.orderBy(ordering[0], ordering[1]);
      }

      return query;
    },
    initPageVideos() {
      this.lastVisible = undefined;
      let vidVar = [];
      let next = this.getComplexQuery().limit(5);
      this.busy = true;
      next.get().then((querySnapshot) => {
        if (querySnapshot.docs.length) {
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; //.data()[this.orderingToQueryData[this.filterData.order][0]];
          querySnapshot.forEach((doc) => {
            vidVar.push(doc.data());
          });
          // Replace vids
          this.videos = vidVar;
          this.busy = false;
          this.fetchUntilScroll();
          if (querySnapshot.docs.length < 5) {
            this.lastVisible = undefined;
          }
        } else {
          // All data fetched
          this.lastVisible = undefined;
          this.videos = [];
        }
      });
    },
    async fetchMoreVideos() {
      // lastVisible is undefined, if we have fetch all documents (or the first query isn't executed)
      if (!this.lastVisible) {
        return;
      }
      this.busy = true;

      let next = this.getComplexQuery().startAfter(this.lastVisible).limit(5);

      let querySnapshot = await next.get();

      if (querySnapshot.docs.length) {
        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; //.data()[this.orderingToQueryData[this.filterData.order][0]];
        querySnapshot.forEach((doc) => {
          this.videos.push(doc.data());
        });
        if (querySnapshot.docs.length < 5) {
          this.lastVisible = undefined;
        }
      } else {
        // All data fetched
        this.lastVisible = undefined;
      }
      this.busy = false;
    },
    handleScroll() {
      if (window.pageYOffset == 0) {
        this.topOrBottom = true;
      } else if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight
      ) {
        this.topOrBottom = true;
      } else {
        this.topOrBottom = false;
      }
    },
  },
  created: function () {
    this.db = firebase.firestore();
    this.initPageVideos();
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    admin() {
      return this.$store.getters.admin;
    },
  },
  data() {
    return {
      db: undefined,
      videos: [],
      lastVisible: undefined,
      busy: false,
      orderingToQueryData,

      topOrBottom: true,
      filterData: {
        qualities: ["144p", "4K"],
        years: [2011, 2020],
        archiveTimes: ["9/2020", "10/2020"],
        durations: "__vue_devtool_undefined__",
        brands: true,
        individuals: true,
        category: "Kaikki kategoriat",
        city: "Kaikki sijainnit",
        order: "Arkistointiaika (uusin ensin)",
        limitYears: false,
        creator: "Kaikki tekijät",
        name: "Kaikki nimet",
        title: "Kaikki leffojen nimet",
      },
    };
  },
  metaInfo() {
    return {
      title: "Skedeleffakokoelman etusivu - Skeittivideot.fi",
      meta: [
        {
          name: "description",
          content:
            "Skeittivideot.fi on suomalaiset skedeleffat kokoava arkisto. Kuka tahansa voi lisätä oman suosikkivideonsa arkistoon. Yhteisöllinen arkisto on toteutettu modernisti, joten hyvien leffojen löytäminen on todella miellyttävää!",
        },
        {
          property: "og:title",
          content: "Skedeleffakokoelman etusivu - Skeittivideot.fi",
        },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-container {
  /*
  display: grid;
  */
}
.main {
  /*
  display: block;
  */
  margin-bottom: 100px;
}
.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer {
  position: fixed;
  bottom: 0px;
}
.item-wrapper {
  margin: auto;
}
a {
  text-decoration: none;
}
</style>
