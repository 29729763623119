export const qualityStrToNum = {
  "144p": 144,
  "240p" : 240,
  "360p": 360,
  "480p": 480,
  "540p": 540,
  "720p": 720,
  "1080p": 1080,
  "2K": 1440,
  "4K": 2160,
}
export const qualityNumToStr = {
  144: "144p",
  240: "240p",
  360: "360p",
  480: "480p",
  540: "540p",
  720: "720p",
  1080: "1080p",
  1440: "2K",
  2160: "4K",
}

export const qualityStrs = [
  "144p",
  "240p",
  "360p",
  "480p",
  "540p",
  "720p",
  "1080p",
  "2K",
  "4K",
]
export const qualityNums = [
  144,
  240,
  360,
  480,
  540,
  720,
  1080,
  1440,
  2160,
]