<template>
  <div class="page">
    <div v-if="false">
      <h1>Ladataan</h1>
    </div>
    <div>
      <h2>{{ video.title || "" }}</h2>
      <VideoPlayer
        v-if="video.provider && video.provider === 'youtube'"
        :item="video"
      />
      <VideoPlayer
        v-if="video.provider && video.provider === 'vimeo'"
        :item="video"
      />
      <p v-if="video.dateAdded">
        Ehdotusajankohta:
        <b>{{ new Date(video.dateAdded).toLocaleString("fi") }}</b>
      </p>
      <p v-if="video.comment">
        Kommentti ylläpidolle: <b>{{ video.comment || "" }}</b>
      </p>

      <p v-if="!editBrand">
        <button class="edit" @click="editBrand = true">Muokkaa</button>
        Merkki vai yksityinen posse:
        <b>{{ video.brand ? "Merkki" : "Yksityisposse" }}</b>
      </p>
      <div v-if="editBrand" class="inline-container checkbox-container">
        <button
          class="decline"
          @click="
            editBrand = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <input id="company-checkbox" v-model="tmpVideo.brand" type="checkbox" />
        <label for="company-checkbox">Skedemerkin leffa</label>
        <button
          class="accept"
          @click="
            editBrand = false;
            video.brand = tmpVideo.brand;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editTitle">
        <button @click="editTitle = true" class="edit">Muokkaa</button> Nimi

        <b> {{ video.title || "" }} </b>
      </p>

      <div v-if="editTitle" class="inline-container">
        <button
          class="decline"
          @click="
            editTitle = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="title">Nimi</label>
        <input
          type="text"
          id="title"
          class="v-select"
          v-model="tmpVideo.title"
        />

        <button
          class="accept"
          @click="
            editTitle = false;
            video.title = tmpVideo.title;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editCreator">
        <button @click="editCreator = true" class="edit">Muokkaa</button> Tekijä
        {{ video.brand ? "(Merkki): " : "(Henkilö): " }}
        <b> {{ video.creator || "" }} </b>
      </p>

      <div v-if="editCreator" class="inline-container">
        <button
          class="decline"
          @click="
            editCreator = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="creator">Tekijä</label>
        <input
          type="text"
          id="creator"
          class="v-select"
          v-model="tmpVideo.creator"
        />

        <button
          class="accept"
          @click="
            editCreator = false;
            video.creator = tmpVideo.creator;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editDescription">
        <button @click="editDescription = true" class="edit">Muokkaa</button>
        Kuvaus:
        <b> {{ video.description || "" }} </b>
      </p>

      <div v-if="editDescription" class="inline-container">
        <button
          class="decline"
          @click="
            editDescription = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="creator">Kuvaus</label>
        <input
          type="text"
          id="creator"
          class="v-select"
          v-model="tmpVideo.description"
        />

        <button
          class="accept"
          @click="
            editDescription = false;
            video.description = tmpVideo.description;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editLocation">
        <button class="edit" @click="editLocation = true">Muokkaa</button>
        Leffapossen / leffan paikkakunta:
        <b>{{ video.location || "" }}</b>
      </p>
      <div v-if="editLocation" class="inline-container checkbox-container">
        <button
          class="decline"
          @click="
            editLocation = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="releaseYear">Paikkakunta</label>
        <VSelect
          id="releaseYear"
          class="v-select"
          v-model="tmpVideo.location"
          :options="cities"
        />

        <button
          class="accept"
          @click="
            editLocation = false;
            video.location = tmpVideo.location;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editYear">
        <button class="edit" @click="editYear = true">Muokkaa</button>
        Julkaisuvuosi
        <b>{{ video.year || "" }}</b>
      </p>
      <div v-if="editYear" class="inline-container">
        <button
          class="decline"
          @click="
            editYear = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="releaseYear">Julkaisuvuosi</label>
        <VSelect
          id="releaseYear"
          class="v-select"
          v-model="tmpVideo.year"
          :options="releaseYears"
        />

        <button
          class="accept"
          @click="
            editYear = false;
            video.year = tmpVideo.year;
          "
        >
          Päivitä
        </button>
      </div>

      <p v-if="!editCategory">
        <button class="edit" @click="editCategory = true">Muokkaa</button>
        Kategoria:
        <b>{{ video.category || "" }}</b>
      </p>
      <div v-if="editCategory" class="inline-container checkbox-container">
        <button
          class="decline"
          @click="
            editCategory = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="category">Kategoria</label>
        <VSelect
          id="category"
          class="v-select"
          v-model="tmpVideo.category"
          :options="categories"
        />

        <button
          class="accept"
          @click="
            editCategory = false;
            video.category = tmpVideo.category;
          "
        >
          Päivitä
        </button>
      </div>
      <p v-if="!editQuality">
        <button class="edit" @click="editQuality = true">Muokkaa</button>
        Kuvanlaatu:
        <b>{{ qualityNumToStr[video.quality] }}</b>
      </p>
      <div v-if="editQuality" class="inline-container">
        <button
          class="decline"
          @click="
            editQuality = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="quality">Kuvanlaatu</label>
        <VSelect
          id="quality"
          class="v-select"
          v-model="tmpQualityStr"
          v-on:change="tmpVideo.quality = qualityStrToNum[tmpQualityStr]"
          :options="qualityStrs"
        />

        <button
          class="accept"
          @click="
            editQuality = false;
            video.quality = qualityStrToNum[tmpQualityStr];
          "
        >
          Päivitä
        </button>
      </div>
      <p v-if="!editMultipart">
        <button class="edit" @click="editMultipart = true">Muokkaa</button>
        Moniosainen video:
        <b v-if="!video.multipart">Yhtenäinen video</b>
        <a
          target="_blank"
          :href="getVideoLink(video.prevVideo)"
          v-if="video.prevVideo && video.prevVideo.videoId"
          >Aiempi osa </a
        ><br />
        <a
          target="_blank"
          :href="getVideoLink(video.nextVideo)"
          v-if="video.nextVideo && video.nextVideo.videoId"
          >Seuraava osa</a
        >
      </p>
      <div v-if="editMultipart" class="inline-container">
        <button
          class="decline"
          @click="
            editMultipart = false;
            undoEdit();
          "
        >
          Peruuta
        </button>

        <input type="checkbox" id="multipart" v-model="tmpVideo.multipart"/>
        <label for="multipart">Moniosainen video</label>
        <div v-if="tmpVideo.multipart" class="col">
          <p><strong>Edellinen video</strong></p>
          <p>Id</p>
          <input type="text" v-model="tmpVideo.prevVideo.videoId" />
          <p>Palvelu "vimeo" tai "youtube"</p>
          <VSelect
          class="v-select"
          v-model="tmpVideo.prevVideo.provider"
          :options="['youtube', 'vimeo']"
        />
        </div>
        <div v-if="tmpVideo.multipart" class="col">
          <p><strong>Seuraava video</strong></p>
          <p>Id</p>
          <input type="text" v-model="tmpVideo.nextVideo.videoId" />
          <p>Palvelu "vimeo" tai "youtube"</p>
          <VSelect
          class="v-select"
          v-model="tmpVideo.nextVideo.provider"
          :options="['youtube', 'vimeo']"
        />
        </div>

        <button
          class="accept"
          @click="
            editMultipart = false;
            handleMultipartAccept();
          "
        >
          Päivitä
        </button>
      </div>
      <p v-if="!editCamera">
        <button class="edit" @click="editCamera = true">Muokkaa</button> Kamera:
        <b>{{ video.camera || "" }}</b>
      </p>
      <div v-if="editCamera" class="inline-container">
        <button
          class="decline"
          @click="
            editCamera = false;
            undoEdit();
          "
        >
          Peruuta
        </button>
        <label for="camera">Kamera</label>
        <VSelect
          id="camera"
          class="v-select"
          v-model="tmpVideo.camera"
          :options="cameras"
        />

        <button
          class="accept"
          @click="
            editCamera = false;
            video.camera = tmpVideo.camera;
          "
        >
          Päivitä
        </button>
      </div>

      <div class="border">
        <button  class="small-button" @click="handleGetSongs(video.videoId)">
          Hae automaattisesti
        </button>
        <span v-if="fetchingSongs">Haetaan...</span>
        <br>
        <button v-if="!editSongs" class="edit" @click="editSongs = true">
          Muokkaa
        </button>

        <div v-if="editSongs" class="row">
          <input type="text" v-model="tmpSong" v-on:keyup.enter="addSong()" />
          <button class="accept" @click="addSong()">Lisää biisi</button>
        </div>
        <p>Biisit:</p>
        <div v-if="!editSongs">
          <p v-for="song in video.songs" :key="song">
            <b>{{ song }}</b>
          </p>
        </div>
        <ul class="listing-ul" v-if="editSongs">
          <li
            v-for="(listedSong, index) in tmpVideo.songs"
            class="listing"
            :title="'Poista biisi (' + listedSong + ') listasta'"
            :key="index + listedSong"
          >
            <button :id="'song-' + listedSong" v-on:click="removeSong(index)">
              X
            </button>
            <label :for="'song-' + listedSong">{{ listedSong }}</label>
          </li>
        </ul>
        <div v-if="editSongs" class="row">
          <button
            class="decline"
            @click="
              editSongs = false;
              undoEdit();
            "
          >
            Peruuta
          </button>
          <button
            class="accept"
            @click="
              editSongs = false;
              video.songs = tmpVideo.songs;
            "
          >
            Päivitä
          </button>
        </div>
      </div>

      <div class="border">
        <button  class="small-button" @click="handleCopyPaste()">
          Copy-Paste lisäys
        </button>
        <br>
        <button v-if="!editSkaters" class="edit" @click="editSkaters = true">
          Muokkaa
        </button>

        <div v-if="editSkaters" class="row">
          <input
            type="text"
            v-model="tmpSkater"
            v-on:keyup.enter="addSkater()"
          />
          <button class="accept" @click="addSkater()">Lisää skeittari</button>
        </div>
        <p>Skeittarit:</p>
        <div v-if="!editSkaters">
          <p v-for="skater in video.skaters" :key="skater">
            <b>{{ skater }}</b>
          </p>
        </div>
        <ul class="listing-ul" v-if="editSkaters">
          <li
            v-for="listedSkater in tmpVideo.skaters"
            class="listing"
            :title="'Poista skeittari (' + listedSkater + ') listasta'"
            :key="listedSkater"
          >
            <button
              :id="'skater-' + listedSkater"
              v-on:click="removeSkater(listedSkater)"
            >
              X
            </button>
            <label :for="'skater-' + listedSkater">{{ listedSkater }}</label>
          </li>
        </ul>
        <div v-if="editSkaters" class="row">
          <button
            class="decline"
            @click="
              editSkaters = false;
              undoEdit();
            "
          >
            Peruuta
          </button>
          <button
            class="accept"
            @click="
              editSkaters = false;
              video.skaters = tmpVideo.skaters;
            "
          >
            Päivitä
          </button>
        </div>
      </div>

      <div class="accept-decline bottom">
        <button @click="decline" class="decline">Poista video</button>
        <button @click="accept" class="accept">Hyväksy video</button>
      </div>
    </div>
    <div style="flex-grow: 1"></div>
  </div>
</template>

<script>
import VSelect from "vue-select";
import categories from "../categories";
import {
  qualityStrs,
  qualityNumToStr,
  qualityStrToNum,
} from "../videoQualities";
import VideoPlayer from "../components/VideoPlayer.vue";
import cities from "../cities.js";
import cameras from "../cameras.js";

export default {
  name: "AdminVideoEditor",
  components: {
    VideoPlayer,
    VSelect,
  },
  props: {
    videoToEdit: Object,
  },
  methods: {
    handleCopyPaste() {
      console.log("handlecopypaste");
      const skatersStr = prompt(
        "Liitä lista skeittareista tähän (esim. videon kuvauksesta)."
      );
      if (!skatersStr) {
        return;
      }
      const separator = prompt(
        "Liitä listan erottimena käytetty merkki, jätä tyhjäksi, mikäli kyseessä on rivinvaihto.\n\nAiemmin liitetty lista:\n" +
          skatersStr
      );
      let skatersList = [];
      if (separator) {
        skatersList = skatersStr.split(separator);
      } else if (separator !== null) {
        skatersList = skatersStr.split("\n");
      }
      for (let i = 0; i < skatersList.length; i++) {
        skatersList[i] = skatersList[i].trim();
      }
      if(!this.video.skaters){
        this.video.skaters = []
      }
      console.log("this.video.skaters old --> " + this.video.skaters );
      console.log(this.video.skaters);
      let newSkaters = []
      if (skatersList.length > 1) {
        newSkaters = this.video.skaters.concat(skatersList);
      }
      
      //this.video.skaters = newSkaters

      // Remove duplicates
      this.video.skaters = newSkaters.filter((item, pos) => {
        return newSkaters.indexOf(item) == pos;
      });
      // Why Vue not updating without this timeout hack?
      setTimeout(()=>{this.editSkaters = true},20)
      setTimeout(()=>{this.editSkaters = false},40)
      
    },
    accept() {
      this.$emit("accept-edit", this.video);
    },
    decline() {
      this.$emit("decline-edit", this.video);
    },

    addSong() {
      if (!this.tmpSong.includes(" - ")) {
        this.$errorToast(
          "Väärin muotoiltu biisi.<br/>Laita musiikki muodossa Artisti - Biisi"
        );
        return;
      }
      let trimmedsong = this.tmpSong.trim().replace(/\s\s+/g, " ");
      if (!this.tmpVideo.songs) {
        this.tmpVideo.songs = [trimmedsong];
        this.tmpSong = "";
      } else if (!this.tmpVideo.songs.includes(trimmedsong)) {
        this.tmpVideo.songs.push(trimmedsong);
        this.tmpSong = "";
      } else {
        this.$errorToast(
          "Biisi löytyy jo listalta"
        );
        return;
      }
    },
    async handleGetSongs(id) {
      console.log("Sending req");
      this.fetchingSongs = true
      fetch("https://ytsongs-rttg5v3iwq-lz.a.run.app/" + id, {mode:'cors'})
        .then(response => response.json())
        .then(myJson => {
          this.fetchingSongs = false
          let music = myJson.music
          if(!this.video.songs){
            this.video.songs = []
          }
          if(music && music.length){
            for(let song of music){
              let trimmedsong = song.trim().replace(/\s\s+/g, " ");
              if (this.video.songs.includes(trimmedsong)) {
                continue
              }
              this.video.songs.push(trimmedsong);
              }
          }
          
        });
    },
    removeSkater(skater) {
      let index = this.tmpVideo.skaters.indexOf(skater);
      this.tmpVideo.skaters.splice(index, 1);
    },
    addSkater() {
      if (this.tmpSkater.length < 2) {
        return;
      }
      let trimmedskater = this.tmpSkater.trim().replace(/\s\s+/g, " ");
      if (!this.tmpVideo.skaters) {
        this.tmpVideo.skaters = [trimmedskater];
        this.tmpSkater = "";
      } else if (!this.tmpVideo.skaters.includes(trimmedskater)) {
        this.tmpVideo.skaters.push(trimmedskater);
        this.tmpSkater = "";
      } else {
        this.$errorToast("Skeittari on jo listalla", 4000);
      }
    },
    removeSong(index) {
      let songs = JSON.parse(JSON.stringify(this.tmpVideo.songs));
      songs.splice(index, 1);
      this.tmpVideo.songs = songs;
    },
    undoEdit() {
      this.tmpVideo = JSON.parse(JSON.stringify(this.video));
      this.tmpQualityStr = this.qualityNumToStr[this.video.quality];
    },
    getVideoLink(data) {
      if (data.provider === "youtube") {
        return "https://www.youtube.com/watch?v=" + data.videoId;
      }
      if (data.provider === "vimeo") {
        return "https://www.vimeo.com/" + data.videoId;
      }
    },
    handleMultipartAccept(){
      this.video.multipart = this.tmpVideo.multipart;
      if(!this.video.multipart){
        this.video.nextVideo = {provider: "", videoId: ""};
        this.tmpVideo.nextVideo = {provider: "", videoId: ""};
        this.video.prevVideo = {provider: "", videoId: ""};
        this.tmpVideo.prevVideo = {provider: "", videoId: ""};
      }
      else{
        if(this.tmpVideo.prevVideo){
          this.video.prevVideo = this.tmpVideo.prevVideo
        }
        else{
          this.video.prevVideo = {provider: "", videoId: ""};
        }
        if(this.tmpVideo.nextVideo){
          this.video.nextVideo = this.tmpVideo.nextVideo
        }
        else{
          this.video.nextVideo = {provider: "", videoId: ""};
        }
      }
    }
  },
  created: function () {},
  data() {
    let releaseYears = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1990; i <= currentYear; i++) {
      releaseYears.push(i);
    }
    let tmpVideo = JSON.parse(JSON.stringify(this.$props.videoToEdit));
    if(!tmpVideo.prevVideo){
      tmpVideo.prevVideo = {provider : "", videoId: ""}
    }
    if(!tmpVideo.nextVideo){
      tmpVideo.nextVideo = {provider : "", videoId: ""}
    }
    return {
      video: JSON.parse(JSON.stringify(this.$props.videoToEdit)),
      tmpVideo,
      qualityNumToStr,
      releaseYears,
      editBrand: false,
      editSongs: false,
      editSkaters: false,
      editCreator: false,
      editTitle: false,
      editDescription: false,
      editLocation: false,
      editYear: false,
      editCategory: false,
      editQuality: false,
      editCamera: false,
      editMultipart: false,
      fetchingSongs: false,
      categories,
      cities,
      cameras,
      qualityStrs,
      tmpQualityStr: qualityNumToStr[this.$props.videoToEdit.quality],
      qualityStrToNum,
      tmpSong: "",
      tmpSkater: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  width: fit-content;
  border: 2px solid;
}
.bottom {
  margin-bottom: 50px !important;
}
.accept-decline {
  display: flex;
  justify-content: space-around;
  width: 500px;
  max-width: 90%;
  margin: auto;
  margin-top: 50px;
}
.listing {
  padding-left: 5px;
  margin-left: 5px;
  height: 30px;
  display: flex;
  width: fit-content;
  justify-content: flex-start;
}
.listing-ul {
  width: fit-content;
  margin-bottom: 30px;
  margin-top: -10px;
  padding-left: 5px;
  margin-left: 5px;
  justify-content: flex-start;
  justify-items: start;
}
.listing * {
  cursor: pointer;
}
.listing button {
  background: none;
  color: red;
  width: 20px;
  height: 20px;
  border: none;
  position: relative;
  bottom: 7px;
  font-size: 20px;
}
button {
  color: white;
  background-color: rgb(70, 125, 196);
  border-radius: 5px;
  border: 1px solid silver;
  width: 150px;
  height: 50px;
  cursor: pointer;
}
.accept {
  background-color: rgb(41, 161, 41);
}
.accept:hover {
  background-color: rgb(29, 197, 29);
}
.decline {
  background-color: rgb(180, 31, 5);
}
.decline:hover {
  background-color: rgb(221, 49, 18);
}
.edit {
  width: fit-content;
  height: 40px;
}
.v-select {
  max-width: 400px;
  min-width: 150px;
}
.inline-container * {
  display: inline-block;
  margin: 5px;
}
.col *{
  display: block;
}
.small-button{
  font-size: smaller;
  padding: 5px;
  height: auto;
  width: auto;
}
</style>
