<template>
  <div class="page">
    <div class="content">
    <Logo />
    <Navigation />

    <h1>{{ "Arkiston ylläpitäjät" }}</h1>
    <p>Mikäli haluat mukaan ylläpitäjäksi, lue 
      <router-link to="tietoa">tietoa</router-link> osio ja laita viestiä!
    </p>
    <div class="admin-container" v-for="admin in admins" :key="admin.name + admin.title">
      <h2>{{admin.name || "Anonyymi"}}</h2>
      <a v-if="admin.instagram" :href="'https://instagram.com/' + admin.instagram">{{"@" + admin.instagram}}</a>
      <router-link v-if="admin.video" :to="'/videot/' + admin.video">
      Saitin paras video
      </router-link>
    </div>

  </div>
  <div style="flex-grow:1"></div>
  <Footer class="footer"/>
  
  </div>
</template>

<script>
import Logo from '../components/Logo.vue'
import Footer from '../components/Footer.vue'
import Navigation from '../components/Navigation.vue'
export default {
  name: "AdminsPage",
  components: {
    Logo,
    Footer,
    Navigation

  },
  props: {
  },
  methods: {

  },
  created: function () {

  },
    computed: {
    admins() {
        
        return this.$store.state.adminList.filter((admin) => admin.visible);
    },
},
  data() {
    return {
    };
  },
                  metaInfo() {
        return { 
            title: "Ylläpitäjät - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  "Skeittivideot.fi ylläpitäjät listattuna. Fiilistele ylläpitäjien suosikkileffoja ja Instagram profiileja tai liity mukaan joukkoon."},
                { property: 'og:title', content: "Ylläpitäjät - Skeittivideot.fi"},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.admin-container{
  border: 1px solid burlywood;
  border-radius: 20px;
  max-width: 400px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}
.admin-container *{
  display: block;
}

</style>
