<template>
  <div class="page">
    <div class="content">
    <Logo />
    <Navigation />
    <h1>{{ "Not found..." }}</h1>

  </div>
  <div style="flex-grow:1"></div>
  <Footer class="footer"/>
  
  </div>
</template>

<script>
import Logo from '../components/Logo.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
export default {
  name: "NotFound",
  components: {
    Logo,
    Footer,
    Navigation

  },
  props: {
  },
  methods: {

  },
  created: function () {

  },
  data() {
    return {
    };
  },
                    metaInfo() {
        return { 
            title: "Sivua ei löytynyt - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  "Valitettavasti etsimääri rullalautailuvideoihin liittyvää sivua ei löytynyt..."},
                { property: 'og:title', content: "Sivua ei löytynyt - Skeittivideot.fi"},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
