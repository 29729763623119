<template>
  <div>
    <div class="suggestion-content">
      <h2>Jatka katselua</h2>
            <router-link v-if="item && item.videoId && data" :to="'/videot/' + data.videoId + (data.t ? ('/?t=' + data.t) : '')">
              <div class="row" style="height:100px">
                <div>

                
                        <YoutubeThumb
                          style="height:100px"
                          class="lib-img"
                          :videoId="item.videoId"
                          :alt="'Rullalautailuvideo ' +  item.title + ' vuodelta ' + item.year"
                          v-if="item.provider === 'youtube'"
                        />
                        <VimeoThumb
                          style="height:100px"
                          class="lib-img"
                          :videoId="item.videoId"
                          :alt="'Skedeleffa ' +  item.title + ' vuodelta ' + item.year"
                          v-if="item.provider === 'vimeo'"
                        />
            <div
              :class="
                'duration ' + (item.provider === 'youtube' ? 'yt' : 'vm')
              "
            >
              <p>{{ getDurationStr() }}</p>
            </div>
                  </div>
                <div class="text-col">
                  <p>{{item.creator}}</p>
                  <p>{{item.title}}</p>
                  <p>{{item.location}}</p>
                  <p>{{item.year}}</p>
                </div>
              </div>

            </router-link>

    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import YoutubeThumb from "./YoutubeThumb.vue";
import VimeoThumb from "./VimeoThumb.vue";

export default {
  name: "ContinueWatching",
  components: {
    YoutubeThumb,
    VimeoThumb
  },
  props: {
  },
  methods: {
    async fetchVideo(){
      let snapShot = await this.db.collection("videos").where("videoId", "==", this.data.videoId).get()
      
      if(!snapShot.docs.lenght < 1){

        return
      }
      this.item = snapShot.docs[0].data()
    },
    getDurationStr(){
      let totalSeconds = this.item.duration;
      let strToReturn = this.secondsToDurationStr(this.data.t) + "/" + this.secondsToDurationStr(totalSeconds)
      return strToReturn
    },
    secondsToDurationStr(totalSeconds){
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      seconds = String(seconds).padStart(2, "0");
      if (hours > 0) {
        return hours + ":" + minutes + ":" + seconds;
      }
      return minutes + ":" + seconds;
    }

  },
  created: function () {
    this.db = firebase.firestore();
    this.data = JSON.parse(localStorage.getItem("resumeVideo"))
    this.item = this.fetchVideo()
  },
  data() {
    return {
      data: null,
      item: null
    };
  },
};
</script>

<style scoped>

.row {

  display: inline-flex;
}
.text-col{
  text-align: left;
  margin-left: 10px;
  margin-top: 0px;
}
.text-col *{

  margin-top: 0px;
  margin-bottom: 2px;
}
.suggestion-content *{
  text-decoration: none;
}
.suggestion-content{
  margin-bottom: 40px;
  }
.duration {
  height: 20px;
  width: 90%;
  border-radius: 5px;
  color: white;
  text-align: center;
  position: relative;
  bottom: 22px;
  margin: auto;
  
}
.yt {
  background-color: #ff0000;
}
.vm {
  background-color: rgb(0, 173, 239);
}
.duration * {
  margin: auto;
}
.quality * {
  margin: auto;
}

</style>
