<template>
  <div class="page">
    <Logo />
      <Navigation/>
    <div class="content">
      
      <h1>{{ "Mun tiedot" }}</h1>

      <p>
        Mikäli et halua näkyä ylläpitäjät sivulla, laita julkinen boksi pois
        päältä.
      </p>
      <p>
        Mikäli et halua suosikkivideotasi näytettävän etusivulla, jätä videon
        tunniste kenttä tyhjäksi. Suosikkivideon ID:n voi hakea kyseisen videon osoitteesta. Sivuston videoiden osoitteet ovat muotoa ... .fi/videot/ID
      </p>
      <p>Tietojen päivityksen näkyminen saattaa vaatia selainikkunan päivittämisen.</p>
      <div class="form-box">
        <h2>Muokkaa omia tietojasi</h2>

        <div class="inline-container checkbox-container">
          <input id="visible-checkbox" v-model="myData.visible" type="checkbox" />
          <label for="visible-checkbox">Julkinen</label>
        </div>
        <label for="title">Nimi</label>
        <input
          type="text"
          id="name"
          v-model="myData.name"
          placeholder="Käyttäjän nimi"
        />
        
        
        <label for="title">Instagram nimi</label>
        <div class="inline-container">
          <p><b>@</b></p>
        <input
          type="text"
          id="instagram"
          v-model="myData.instagram"
          placeholder="Instagram nimi"
        />
        </div>
        <label for="title">Korostettu video (id osoitteessa)</label>
        <div class="inline-container">
          <p><b>ID</b></p>
        <input
          type="text"
          id="video"
          v-model="myData.video"
          placeholder="Videon ID"
        />
        </div>
        

        <button class="submit" v-on:click="handleSubmit()">Lähetä</button>
      </div>
    </div>
    
    <button class="submit" style="width: 200px; margin: auto;" v-on:click="cleanSearch()">Hakukenttien uudestaangenerointi</button>
    <div style="height:40px;"/>
    <div style="flex-grow:1;"></div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import Navigation from "../components/Navigation.vue";
import Footer from "../components/Footer.vue";
import firebase from "firebase/app";
export default {
  name: "MyInfo",
  components: {
    Logo,
    Navigation,
    Footer,
  },
  props: {},
  methods: {
    async fetchMyInfo(){
      let docs = await this.db.collection("users").doc(this.$store.state.user.uid).get()
      this.myData = docs.data();

    },
    async cleanSearch(){
      if(!confirm("Generoidaanko hakukentät uudestaan? \n\n Käytännössä siis päivitetään tekijän, skeittarin, leffan nimen tai artistin avulla tapahtuvan haun ehdotukset. Tämä kannattaa tehdä, jos muokkaa kyseisiä tietoja jo sivustolta löytyvistä videoista.")){
        return;
      }
      console.log("cleansearch");
      let allSnapshot = await this.db.collection("videos").get();
      let newTitles = [];
      let newCreators = [];
      let newSkaters = [];
      let newArtists = [];
      allSnapshot.forEach((doc) => {
            const data = doc.data()
            if(!newTitles.includes(data.title)){
              newTitles.push(data.title);
            }
            if(!newCreators.includes(data.creator_low)){
              newCreators.push(data.creator_low);
            }
            for( const skater of data.skaters_low){
              if(!newSkaters.includes(skater)){
              newSkaters.push(skater);
              }
            }
            for( const artist of data.artists_low){
              if(!newArtists.includes(artist)){
              newArtists.push(artist);
              }
            }
        });
      
      
      newTitles = newTitles.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
      this.db.collection("search").doc("titles").set({list: newTitles}).then(function() {
        console.log("Titles successfully written!");
      });

      newCreators = newCreators.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
      this.db.collection("search").doc("creators").set({list: newCreators}).then(function() {
        console.log("newCreators successfully written!");
      });
      newSkaters = newSkaters.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
      this.db.collection("search").doc("skaters").set({list: newSkaters}).then(function() {
        console.log("newSkaters successfully written!");
      });
      newArtists = newArtists.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
      this.db.collection("search").doc("artists").set({list: newArtists}).then(function() {
        console.log("newArtists successfully written!");
      });


      this.$toast("Hakukentät generoitu uudestaan. Päivitä sivu nähdäksesi vaikutuksen.")
    },
    /*
    handleMigrate was used to migrate old database (youtube) items descriptions newlines from \n to <br/> 
    */
    async handleMigrate(){
      let querySnapshot = await this.db.collection("videos").where("provider", "==", "youtube").get();
      querySnapshot.forEach( (doc) => {
        
        let desc = doc.data().providerDescription;
        if(desc.includes("\n")){
          let newDesc = desc.replaceAll("\n", "<br/>");
          console.log(newDesc);
          this.db.collection("videos").doc(doc.id).update({providerDescription: newDesc});
        }
      });
    },
    handleAdminRoute(){
      if(!this.$store.getters.admin){

        if(localStorage.getItem("usedToAdmin")){
          this.$router.replace("/admin")
        }
        else{
          this.$router.replace("/")
        }
        return false
      }
      return true
    },
    handleSubmit(){
      this.db.collection("users").doc(this.$store.state.user.uid).set(this.myData)
    },
  },
  created: function () {
    if(!this.handleAdminRoute()){
      return
    }
    this.db = firebase.firestore()
    
    this.fetchMyInfo()
  },
  data() {
    return {
      myData: {},
    };
  },
                    metaInfo() {
        return { 
            title: "Omat tiedot - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  ""},
                { property: 'og:title', content: ""},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'noindex'} 
            ]
        }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
button {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
label {
  margin-top: 20px !important;
}
.checkbox-container {
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.checkbox-container label {
  padding-left: 10px;
}
.checkbox-container * {
  cursor: pointer;
}
.form-box {
  background-color: rgb(245, 248, 248);
}
.content {
  margin: auto;
  width: 600px;
  max-width: 98%;
}
.inline-container * {
  display: inline;
  max-width: 90%;
  text-align: left;
}
.inline-container p {
  margin-right: 10px;
}
</style>
