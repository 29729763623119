import MainPage from './pages/MainPage.vue'
import VideoPage from './pages/VideoPage.vue'
import NotFound from './pages/NotFound.vue'
import UncheckedPage from './pages/UncheckedPage.vue'
import UncheckedVideoPage from './pages/UncheckedVideoPage.vue'
import AddVideoPage from './pages/AddVideoPage.vue'
import AboutPage from './pages/AboutPage.vue'
import AdminsPage from './pages/AdminsPage.vue'
import MyInfo from './pages/MyInfo.vue'
import FeedbackPage from './pages/FeedbackPage.vue'
import AdminLogin from './pages/AdminLogin.vue'
import EditVideoPage from './pages/EditVideoPage.vue'


export default [
    { path: '/', name: 'home', component: MainPage, props: true },
    { path: '/videos', name: 'home', component: MainPage, props: true },
    { path: '/videos/:id', name: 'videos', component: VideoPage, props: true },
    { path: '/videot/:id', name: 'videos', component: VideoPage, props: true },
    { path: '/lisaavideo', name: 'addVideo', component: AddVideoPage, props: true },
    { path: '/tietoa', name: 'about', component: AboutPage, props: true },
    { path: '/adminit', name: 'admins', component: AdminsPage, props: true },
    { path: '/admin', name: 'adminLogin', component: AdminLogin, props: true },
    { path: '/admin/tiedot', name: 'adminInfo', component: MyInfo, props: true },
    { path: '/admin/muokkaa/:id', name: 'adminEdit', component: EditVideoPage, props: true },
    { path: '/admin/palaute', name: 'feedbacks', component: FeedbackPage, props: true },
    { path: '/admin/tarkistamattomat', name: 'unchecked', component: UncheckedPage, props: true },
    { path: '/admin/tarkistamattomat/:id', name: 'uncheckedVideos', component: UncheckedVideoPage, props: true },
    { path: '*', name: 'notFound', component: NotFound, props: true },
]