<template>
  <div>
    <div class="s-content">
      <h2>{{ $props.title }}</h2>
      <div class="s-container s-border">
        <div class="s-container s-inline">
          <div
            v-for="video in $props.videos"
            :key="'suggestion' + video.videoId + video.provider"
          >
            <router-link :to="'/videot/' + video.videoId">
              <MainListItem class="s-item s-inline-item" v-bind:item="video" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainListItem from "../components/MainListItem.vue";

export default {
  name: "VerticalSuggestions",
  components: {
    MainListItem,
  },
  props: {
    title: String,
    videos: Array,
  },
  methods: {
    handleClick(item) {
      this.$router.push("videot/" + item.videoId);
    },
  },
  created: function () {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* General */

.s-content {
  margin-left: 1vw;
}
.s-container {
  margin: 5px;
  margin: auto;
  min-width: 300px;

  width: 350px;
  overflow-x: hidden;
}
.s-border {
  border: 1px solid #e9e9e9;
}

.s-item {
  border: 2px solid rgb(238, 187, 142);
  width: 280px;
}

/* Inline-block */
.s-inline {
  overflow: auto;
  display: block;
}
.s-inline-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
</style>
