<template>
    <img v-bind:src="thumbUrl" :alt="alt">

</template>

<script>


export default {

  name: 'VimeoThumb',
  props: {
    videoId: String,
    alt: String
  },
   methods: {
    getVideoThumb(){
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => { 
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200){
          let resObj = JSON.parse(xmlHttp.responseText);
          if(resObj && resObj[0] && resObj[0].thumbnail_medium){
            this.thumbUrl = (resObj[0].thumbnail_large);
          }
        }
    }
    let url = "https://vimeo.com/api/v2/video/" + this.videoId + ".json"
    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
    }
  },
    created: function () {
    this.getVideoThumb();
  },
    data() {
    return { 
      thumbUrl : ""
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

