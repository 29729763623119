<template>
  <div class="row noselect">
    <router-link to="/">
   
    <img class="logo-img" src="@/assets/logo.png" height="50px" alt="Skeittivideot.fi logo">
    <h1>{{ "Skeittivideot.fi" }}</h1>
    </router-link >
  </div>
</template>

<script>


export default {
  name: "Logo",

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  color: rgb(178, 215, 243);
  
}
* {
  margin: 0;
  font-style: italic;
    text-shadow: 0 0 2px black; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 2px black;
  -webkit-text-shadow: 0 0 2px black;
}
.row *{
  display: inline-block;
}
.logo-img{
  position: relative;
  bottom: 10px;
  margin-right: 5px;
  margin-left: 32px;
}
h1{
  font-size: 36px
}
@media only screen and (max-width: 350px) {
  h1 {
    font-size: 25px;
    margin-top: 12px;
  }

}
</style>
