<template>
  <div class="page">
    <div class="content">
      <Logo />
      <Navigation />
      <h1>{{ "Palautteet" }}</h1>
      <div>
        <div
          class="row list-item flexcontainer"
          v-for="(item, index) in items"
          :key="item.title + item.feedback + item.id"
        >
          <div class="col start">
            <span
              ><router-link :to="'/videot/' + item.videoId">{{
                item.title
              }}</router-link></span
            >
            <span>{{
              new Date(item.time).toLocaleDateString("fi") +
              " " +
              new Date(item.time).getHours() +
              ":" +
              new Date(item.time).getMinutes()
            }}</span>
          </div>
          <div class="center">{{ item.feedback }}</div>
          <button
            class="end remove"
            title="Poista palaute"
            @click="removeItem(index)"
          >
            X
          </button>
        </div>
        <p v-if="allFetched">Kaikki palautteet haettu</p>
        <button v-if="!allFetched" @click="fetchMoreItems()">
          Hae lisää palautteita
        </button>
      </div>
    </div>
    <div style="flex-grow: 1"></div>
    <Footer />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import Navigation from "../components/Navigation.vue";
import Footer from "../components/Footer.vue";
import firebase from "firebase/app";
export default {
  name: "FeedbackPage",
  components: {
    Logo,
    Footer,
    Navigation,
  },
  props: {},
  methods: {
    async removeItem(index) {
      if (confirm("Poista palaute?")) {
        let a = this.db.collection("feedback").doc(this.items[index].id);
        await a.delete();
        this.fetchFirstItems();
      }
    },
    async fetchFirstItems() {
      let tmpItems = [];
      let result = await this.db
        .collection("feedback")
        .orderBy("time")
        .limit(5)
        .get();
      if (result.docs.length) {
        this.lastVisible = result.docs[result.docs.length - 1];
        result.docs.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          tmpItems.push(data);
        });
        this.items = tmpItems;
        if (result.docs.length < 5) {
          this.allFetched = true;
          this.lastVisible = undefined;
        }
      } else {
        // All data fetched
        this.allFetched = true;
        this.lastVisible = undefined;
      }
    },
    async fetchMoreItems() {
      if (!this.lastVisible) {
        return;
      }
      let result = await this.db
        .collection("feedback")
        .orderBy("time")
        .startAfter(this.lastVisible)
        .limit(5)
        .get();
      if (result.docs.length) {
        this.lastVisible = result.docs[result.docs.length - 1];
        result.docs.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          this.items.push(data);
        });
        if (result.docs.length < 5) {
          this.allFetched = true;
          this.lastVisible = undefined;
        }
      } else {
        // All data fetched
        this.allFetched = true;
        this.lastVisible = undefined;
      }
    },
  },
  created: function () {
    this.db = firebase.firestore();
    this.fetchFirstItems();
  },
  data() {
    return {
      db: undefined,
      items: [],
      lastVisible: undefined,
      allFetched: false,
    };
  },
  metaInfo() {
    return {
      title: "Palautteet - Skeittivideot.fi",
      meta: [
        { name: "description", content: "" },
        { property: "og:title", content: "" },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flexcontainer {
  display: flex;
  flex-direction: row;
}
.list-item {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 1px;
  border-color: rgba(127, 153, 209, 0.2);
  border-style: solid;
}
.row > * {
  display: inline-block;
}
.col > * {
  display: block;
}
.center {
  flex: 16 0 0;
}
.start {
  background: ghostwhite;
  flex: 4 0 0;
}
.end {
  flex: 1 0 0;
}
.remove {
  background: none;
  border: 1px solid red;
  border-radius: 5px;
  font-size: 22px;
  color: red;
  cursor: pointer;
}
.remove:hover {
  background: rgb(252, 231, 231);
}
</style>
