<template>
  <div id="filter">
    <div class="w3-sidebar w3-bar-block w3-card w3-animate-left" id="mySidebar">
      <button class="w3-bar-item w3-button w3-large close" v-on:click="close">
        <strong>Sulje suodatus &times; </strong>
      </button>

      <div class="margin-horizontal checkbox-container marginTop" >
        <input v-model="limitYears" v-on:click="handleLimitYearsChange" id="limit-years" type="checkbox" />
        <label for="limit-years">Suodata vuosilla</label>
      </div>

      <div v-if="limitYears">
        <label class="marginTop"> {{"Julkaisuvuosi (" + years[0] + "-" + years[1] + ")"}} </label>
        <VueSlider
          class="slider"
          v-model="years"
          :max-range="9"
          :min="1990"
          :max="currentYear"
          contained="true"
        />

      </div>

      <div class="margin-horizontal checkbox-container marginTop" >
        <input v-model="brands" id="brand-movies-box" type="checkbox" />
        <label for="brand-movies-box"> Merkkien leffat </label>
      </div>
      <div class="margin-horizontal checkbox-container marginTop">
        <input
          v-model="individuals"
          id="individual-movies-box"
          type="checkbox"
        />
        <label for="individual-movies-box"> Yksityiset leffat </label>
      </div>
           <label class="marginTop"> Kategoria </label>
      <VSelect
        class="margin-horizontal"
        v-model="category"
        :options="filterCategories"
      />
      
            <label class="marginTop"> Sijainti </label>

      <VSelect
        class="margin-horizontal"
        v-model="city"
        :options="filterCities"
      />
                 <label class="marginTop"> Videon tekijä </label>

      <VSelect
        class="margin-horizontal capitalize"
        v-model="creator"
        :options="creators"
      />

                       <label class="marginTop"> Skeittarit ja artistit </label>

      <VSelect
        class="margin-horizontal capitalize"
        v-model="name"
        :options="names"
      />

      <label class="marginTop" for="vidTitle"> Leffan nimi </label>

                      <VSelect
          id="vidTitle"
            class="margin-horizontal"
            v-model="title"
            :options="['Kaikki leffojen nimet'].concat(this.$store.state.titles)"
          />

            <label class="marginTop"> Järjestysperuste </label>
            <VSelect
        class="margin-horizontal"
        v-model="order"
        :options="orderingList"
      />


      <button @click="handleSubmit(); $emit('clicked', getFilterObj())" class="marginTop update">Päivitä</button>
      <div style="height:40px;"/>
    </div>
    <div class="w3-teal">
      <div class="w3-container">
        <button
          id="openNav"
          class="w3-button w3-teal w3-xlarge"
          v-on:click="open"
        >
          &#9776;
        </button>

        <Logo class="filter-logo" />
      </div>
    </div>
  </div>
</template>

<script>

import firebase from "firebase/app";
import Logo from "./Logo.vue";
import cities from "../cities.js";
import { orderingList } from "../orderingList.js";
import categories from "../categories.js";
import VueSlider from "vue-slider-component";
import VSelect from "vue-select";

import "vue-select/dist/vue-select.css";
import { qualityStrs } from "../videoQualities";
import "vue-slider-component/theme/default.css";

export default {
  name: "SideFiltering",
  components: { Logo, VueSlider, VSelect },
  props: {},
  methods: {
    handleSubmit(){
    if (screen.width <= 600) {
      this.close();
    }
    },
    close() {
      document.getElementById("main").style.marginLeft = "0%";
      document.getElementById("mySidebar").style.display = "none";
      document.getElementById("openNav").style.display = "inline-block";
      document.getElementById("admin-suggestions").style.width = "calc(98vw)"
    },
    open() {
      document.getElementById("main").style.marginLeft = "300px";
      document.getElementById("mySidebar").style.width = "300px";
      document.getElementById("mySidebar").style.display = "block";
      document.getElementById("openNav").style.display = "none";
      document.getElementById("admin-suggestions").style.width = "calc(98vw - 300px)"
    },

    async fetchCreators(){
      
      let fetchedData = await this.db.collection("search").doc("creators").get();
      let fetchedList = fetchedData.data().list.sort();
      for (const dataItem of fetchedList) {
        this.creators.push(dataItem)
      }
      
    },
        async fetchSkaters(){
      
      let fetchedData = await this.db.collection("search").doc("skaters").get();
      let fetchedList = fetchedData.data().list.sort();
      for (const dataItem of fetchedList) {
        if(!this.names.includes(dataItem)){
          this.names.push(dataItem)
        }
        
      }
    },
    async fetchArtists(){
      
      let fetchedData = await this.db.collection("search").doc("artists").get();
      let fetchedList = fetchedData.data().list.sort();
      for (const dataItem of fetchedList) {
        if(!this.names.includes(dataItem)){
        this.names.push(dataItem)
        }
      }
    },
    handleLimitYearsChange(e){
      if(e.target.checked){
        this.orderingList.pop()
        this.orderingList.pop()
        if(!this.orderingList.includes(this.order)){
          this.order = this.orderingList[0]
           this.$toast("Julkaisuvuodella suodattaminen ja järjestäminen ei onnistu yhtä aikaa")
        }
      }
      else{
        this.orderingList = [...this.ogOrderingList]
      }
    },

    getFilterObj(){
      let data = {
        qualities: this.qualities,
        years: this.years,
        archiveTimes: this.archiveTimes,
        durations: this.duration,
        brands: this.brands,
        individuals: this.individuals,
        category: this.category,
        city: this.city,
        order: this.order,
        limitYears: this.limitYears,
        creator: this.creator,
        name: this.name,
        title: this.title
      }
      return data;
    },
  },
  

  data() {
    let currentYear = new Date().getFullYear();
    let archiveMonths = [];
    
    //let titles = ["Kaikki leffojen nimet"].concat(this.$store.state.titles)
    
    for (let year = 2020; year <= currentYear; year++) {
      
      for (let month = 1; month <= 12; month++) {
        if(year === 2020 && month < 9 ){
          continue
        }
        if(year === currentYear && month > new Date().getMonth() + 1){
          break;
        }
        archiveMonths.push(month + "/" + year);
      }
    }
    let filterCategories = [...categories];
    if (!filterCategories.includes("Kaikki kategoriat")) {
      filterCategories.unshift("Kaikki kategoriat");
    }

    let filterCities = [...cities];
    if (!filterCities.includes("Kaikki sijainnit")) {
      filterCities.unshift("Kaikki sijainnit");
    }

    return {
      videoId: "",
      durations: [1, 120],
      archiveTimes: [archiveMonths[0], archiveMonths[archiveMonths.length - 1]],
      archiveMonths,
      currentYear,
      years: [currentYear - 9, currentYear],
      qualities: ["144p", "4K"],
      qualityStrs: qualityStrs,
      brands: true,
      individuals: true,
      filterCities,
      city: "Kaikki sijainnit",
      filterCategories,
      category: "Kaikki kategoriat",
      orderingList: [...orderingList],
      ogOrderingList: [...orderingList],
      limitYears: false,
      order: orderingList[0],
      creator: "Kaikki tekijät",
      creators: ["Kaikki tekijät"],
      name: "Kaikki nimet",
      names: ["Kaikki nimet"],
      title: "Kaikki leffojen nimet"
    };
  },
  mounted: function () {
    if (screen.width > 600) {
      this.open();
    }
        this.db = firebase.firestore();
    this.fetchCreators();
    this.fetchSkaters();
    this.fetchArtists();
  },
};
</script>

<style scoped>
#openNav {
  position: fixed;
  z-index: 2000;
  border-radius: 50px;
  left: 0px;
  height: 50px;
}
.w3-teal,
.w3-container,
.w3-button {
  background-color: rgb(75, 174, 255) !important;
  height: 50px;
}
.w3-button:hover {
  background-color: rgb(119, 194, 255) !important;
}
h1 {
  margin: 0px;
  margin-top: -5px;
}
#mySidebar {
  display: none;
}
.w3-sidebar * {
  display: block;
}
.close {
  background: rgb(241, 241, 241) !important;
  text-align: end;
  margin-left: 0px !important;
}
.close:hover {
  color: aliceblue !important;
  background-color: rgb(109, 109, 109) !important;
}

.slider {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.margin-horizontal {
  margin-left: 30px;
  margin-right: 30px;
}
.checkbox-container * {
  cursor: pointer;
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 0px !important;


}
.checkbox-container label {
  position: relative;
  vertical-align: text-top;
  text-align: justify;
  padding-left: 10px;

}

.checkbox-container {
  text-align: left;
}
* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.marginTop {
  margin-top: 20px
}
.update{
  margin: auto;
  margin-top: 50px;
  width: 150px;
  height: 50px;
  background: green;
  color: white;
  border: transparent;
  border-radius: 15px;
  cursor: pointer;
}
.update:hover{
  background: rgb(31, 163, 31);
}
.w3-sidebar{
  overflow-x: hidden;
}
.capitalize{
  text-transform: capitalize;
}
</style>