// List finnish skate brands with lowercase and normal capitalization 

export const brandList = [
  "Ponkes",
  "Tikari",
  "Cartel",
  "Aste",
  "Real Deal",
  "Lamina",
  "El Rio Grind",
  "Kuosi",
  "Napalm Custom",
  "Happy Hour",
  "CTRL",
  "SID",
  "World Peace",
  "Sirpa",
  "Windmill",
  "FLU",
  "Perus",
  "Klapi",
  "Union Five",
  "Eyelevel Clothing",
  "DayOff",
  "Statum",
  "Beyond"
]

export const brandListLow = [
  "ponkes",
  "tikari",
  "cartel",
  "aste",
  "real deal",
  "lamina",
  "el rio grind",
  "kuosi",
  "napalm custom",
  "happy hour",
  "ctrl",
  "sid",
  "world peace",
  "sirpa",
  "windmill",
  "flu",
  "perus",
  "klapi",
  "union five",
  "eyelevel clothing",
  "dayoff",
  "statum",
  "beyond"
]

export const brandToCity = {
  "Ponkes": "Helsinki",
  "Tikari": "Helsinki",
  "Cartel": "Tampere",
  "Aste": "Helsinki",
  "Real Deal": "Oulu",
  "Lamina": "Helsinki",
  "El Rio Grind": "Tampere",
  "Kuosi": "Tampere",
  "Napalm Custom": "Tampere",
  "Happy Hour": "Helsinki",
  "CTRL": "Helsinki",
  "SID": "Helsinki",
  "World Peace": "Helsinki",
  "Sirpa": "Helsinki",
  "Windmill": "Hämeenlinna",
  "FLU": "Helsinki",
  "Perus": "Helsinki",
  "Klapi": "Turku",
  "Union Five": "Helsinki",
  "Eyelevel Clothing": "Helsinki",
  "DayOff": "Turku",
  "Statum": "Jyväskylä",
  "Beyond": "Jyväskylä"
}