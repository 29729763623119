<template>
  <div class="root">
    <Logo />
    <Navigation />
    <div class="video-col">
      <h1>{{ video.title || "" }}</h1>
      <div class="auto-resizable-iframe">
        <div>
          <VideoPlayer
            ref="videoPlayer"
            v-on:video-ended="handleVideoEnd"
            v-if="video.provider"
            :item="video"
          />
        </div>
      </div>

      <div>
        <div v-if="video.multipart" class="video-info-row multipart-div">
          <router-link
            v-if="video.prevVideo && prevVideoChecked"
            :to="'/videot/' + video.prevVideo.videoId"
          >
            <img
              src="@/assets/round-arrow-left.svg"
              alt="Nuoli vasemmalle"
              title="Edellinen videon osa"
              width="50px"
            />
          </router-link>
          <div v-else width="50px"></div>
          <p><strong>Moniosainen video</strong></p>
          <div class="autoplay-div" v-if="video.nextVideo && nextVideoChecked">
            <input type="checkbox" id="autoplay" v-model="autoplay" />
            <label for="autoplay">Autoplay</label>
          </div>
          
          <router-link
            v-if="video.nextVideo && nextVideoChecked"
            :to="'/videot/' + video.nextVideo.videoId"
          >
            <img
              src="@/assets/round-arrow-right.svg"
              alt="Nuoli oikealle"
              title="Seuraava videon osa"
              width="50px"
            />
          </router-link>
          <div v-else width="50px"></div>
        </div>

        <div class="video-info-row">
          <div>
            <div class="tight-row">
              <img
                v-if="video.brand"
                class="video-info-row creator-img"
                src="@/assets/brand.png"
                height="25px"
                title="Skeittimerkki"
                alt="Skeittimerkki"
              />
              <img
                v-if="!video.brand"
                class="video-info-row creator-img"
                src="@/assets/individual.png"
                height="25px"
                title="Skeittipossen leffa"
                alt="Yksityinen skeittiporukka"
              />
              <p>{{ video.creator }}</p>
            </div>
                      <div class="tight-row">
            <p>{{ video.year }}</p>
            <p>→</p>
            <p>
              {{
                video && video.dateAdded
                  ? new Date(video.dateAdded).toLocaleDateString("fi")
                  : ""
              }}
            </p>
          </div>
          </div>
          
          <div class="row-start">
            <p>{{ video.location }}</p>
                    <div class="video-info-row">
          <p>{{ video.category }}</p>
        </div>
          </div>
          <button @click="share">Jaa aloitusajalla</button>
        </div>

        <div v-if="video.skaters">
          <Collapsible :text="video.skaters.join(', ')" :title="'Skeittarit'" />
        </div>
        <div v-if="video.songs">
          <Collapsible :text="video.songs.join('<br/>')" :title="'Biisit'" />
        </div>

        <div v-if="video.description">
          <Collapsible :text="video.description" :title="'Kuvaus'" />
        </div>
        <div v-if="video.providerDescription">
          <Collapsible
            :text="video.providerDescription"
            :title="'Alkuperäinen kuvaus'"
          />
        </div>
      </div>
      <FeedbackBox class="feedback-box" :video="video" />
      <router-link
        :to="'/admin/muokkaa/' + video.videoId"
        v-if="$store.getters.admin"
        >Muokkaa videon tietoja</router-link
      >
    </div>
    <div class="side">
      <VerticalSuggestions
        v-if="relTitle"
        :title="relTitle"
        :videos="relVideos"
      />
    </div>

    <HorizontalSuggestions
      class="hor-sug"
      v-if="relTitle"
      :title="relTitle"
      :videos="relVideos"
    />

    <Footer class="video-page-footer" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import Logo from "../components/Logo.vue";
import Collapsible from "../components/Collapsible.vue";
import FeedbackBox from "../components/FeedbackBox.vue";
import Footer from "../components/Footer.vue";
import HorizontalSuggestions from "../components/HorizontalSuggestions.vue";
import VerticalSuggestions from "../components/VerticalSuggestions.vue";
import Navigation from "../components/Navigation.vue";
import VideoPlayer from "../components/VideoPlayer.vue";

export default {
  name: "VideoPage",
  components: {
    Logo,
    VideoPlayer,
    Footer,
    Navigation,
    FeedbackBox,
    Collapsible,
    HorizontalSuggestions,
    VerticalSuggestions,
  },
  props: {
    url: String,
  },
  methods: {
    handleVideoEnd(){
      localStorage.removeItem("resumeVideo")
      if(!this.autoplay || !this.video.nextVideo || !this.video.nextVideo.videoId){
        return;
      }
      this.$router.push({path: "/videot/" + this.video.nextVideo.videoId + "?autoplay=true"})

    },
    validateParams() {
      if (
        !this.$route.params.id ||
        this.$route.params.id.length < 5 ||
        this.$route.params.id.length > 20
      ) {
        this.$router.replace({ path: "/404" });
      }
    },
    async fetchVideo(id) {
      let querySnapshot = await this.db
        .collection("videos")
        .where("videoId", "==", id)
        .get();
      if (!querySnapshot || querySnapshot.size < 1) {
        this.$router.replace({ path: "/404" });
        return;
      }
      this.video = querySnapshot.docs[0].data();
      setTimeout(() => {
        this.fetchRelativeVideos();
        this.handleMultipart();
      }, 500);
    },
    async handleMultipart() {
      if (!this.video.multipart) {
        return;
      }
      if (this.video.prevVideo) {
        let querySnapshot = await this.db
          .collection("videos")
          .where("videoId", "==", this.video.prevVideo.videoId)
          .limit(1)
          .get();
        if (querySnapshot && querySnapshot.size > 0) {
          this.prevVideoChecked = true;
        }
      }
      if (this.video.nextVideo) {
        let querySnapshot = await this.db
          .collection("videos")
          .where("videoId", "==", this.video.nextVideo.videoId)
          .limit(1)
          .get();
        if (querySnapshot && querySnapshot.size > 0) {
          this.nextVideoChecked = true;
          this.autoplay = true;
        }
      }
    },
    async fetchRelativeVideos() {
      this.relVideos = [];
      let querySnapshot = await this.db
        .collection("videos")
        .where("creator_low", "==", this.video.creator_low)
        .where("videoId", "!=", this.video.videoId)
        .limit(5)
        .get();
      if (!querySnapshot || querySnapshot.size < 1) {
        // TODO Fetch some other data
        return;
      }
      for (let relVideo of querySnapshot.docs) {
        this.relVideos.push(relVideo.data());
      }
      this.relTitle = "Samalta tekijältä";
    },
    async updateVideoStatus(){
      let almostEnded = await this.$refs.videoPlayer.getAlmostEnded()
      let time = Math.round(await this.$refs.videoPlayer.getTime())
      if(almostEnded === false && time > 30){
        localStorage.setItem("resumeVideo", JSON.stringify({videoId: this.video.videoId, t: time}))
      }
      else{
        localStorage.removeItem("resumeVideo")
      }
    },
    async share(){
      let time = Math.round(await this.$refs.videoPlayer.getTime())
      this.copySomething(window.origin + "/videot/" + this.video.videoId + "?t=" + time)
    },
    async copySomething(text) {
    try {
      const toCopy = text
      await navigator.clipboard.writeText(toCopy);
      this.$toast("Linkki kopioitu leikepöydälle")
    }
    catch (err) {
      this.$errorToast("Linkkiä ei voitu kopioida")
    }
  }
  },
  created: function () {
    this.validateParams();
    this.db = firebase.firestore();
    this.fetchVideo(this.$route.params.id);
  },
  mounted(){
    this.videoStateInterval = setInterval(function(){
        this.updateVideoStatus()
    }.bind(this), 5000);
  },

  watch: {
    $route(to) {
      // react to route changes...
      this.validateParams();
      this.db = firebase.firestore();
      this.autoplay = false;
      this.nextVideoChecked= false;
      this.prevVideoChecked= false;
      this.fetchVideo(to.params.id);
      this.video = {};
    },
  },
  data() {
    return {
      videoStateInterval: null,
      db: undefined,
      video: {},
      relVideos: [],
      relTitle: "",
      nextVideoChecked: false,
      prevVideoChecked: false,
      autoplay: false,
    };
  },
  beforeDestroy () {
    clearInterval(this.videoStateInterval)
  },
  metaInfo() {
    return {
      title: this.video.title + " - Skeittivideot.fi",
      meta: [
        {
          name: "description",
          content:
            (this.video.title || "Tämä video") +
            " on suomalainen rullalautailuvideo, joka on kuvattu paikkakunnalla " +
            (this.video.location || "") +
            ". " +
            (this.video.year || "") +
            (this.video.skaters
              ? " tehdyllä skeittivideolla on mukana skedeemässä " +
                (this.video.skaters.join(", ") || "") +
                "."
              : " julkaistun videon on tehnyt " +
                (this.video.creator || "") +
                "."),
        },
        {
          property: "og:title",
          content: (this.video.title || "video") + " - Skeittivideot.fi",
        },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-info-row {
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.tight-row {
  display: flex;
}
.root {
  max-width: 100vw;
}
.video-col,
.side {
  display: inline-block;
}

.video-col {
  max-width: 600px;
  margin: auto;
}
.side {
  position: relative;
  top: 50px;
  margin-bottom: 50px;
  vertical-align: top;
}
.hor-sug {
  display: none;
  max-width: 95vw;
}
@media screen and (max-width: 980px) {
  .side {
    display: none;
  }
  .hor-sug {
    display: block;
  }
}
.feedback-box * {
  max-width: 600px;
}
button{
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  border: none;
  background-color: rgb(75, 174, 255);
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
button:hover{
  background-color: rgb(45, 138, 214);
}
</style>



<style>
.auto-resizable-iframe {
  max-width: 640px;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 75%;
  height: 0px;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.multipart-div{
  padding-top: 5px;
}
.autoplay-div *{
  padding:5px;
  margin-top: 20px;
  
}
.creator-img{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

</style> <!--Not scoped styles-->