<template>
  <div class="page"><Logo />
      <Navigation />
    <div class="content">
      
      <h1>{{ "Tietoa" }}</h1>
      <div>
        <h2>Sivuston ideologia</h2>
        <p>
          Sivuston on toteuttanut <a href="https://jerrysel.in">Jerry Selin</a>.
          Ajatuksena on koota suomiskeittauksen helmet samaan paikkaan.
          Suomiskeittausta fiilistelleenä on tullut huomattua, että
          skeittimedioiden ulkopuolelle jää todella paljon loistokkaita
          mestariteoksia. Uskon, että en ole ainoa, jota kiinnostaa muiden
          paikkakuntien skeittiskene. Oletko koskaan hakenut Youtubesta videoita
          hakusanoilla "Kaupunki X sk8"? Jos olet, tiedät varmaan että ekana
          tuloksiin ei pomppaa paikan parhaat skedeleffat vaan video
          paikkakunnan parkilla skeittaamisesta. Videon lisääjän kanavalla
          näkyykin sitten videoita täysin eri kaupungista, joten takaisin
          keksimään parempia hakusanoja. Tämän arkiston tarkoituksena onkin
          näyttää käyttäjälle juuri niitä videoita kuin hän haluaa. Sivuston
          suurena tavoitteena onkin kattaa kaikki netissä olevat suomen
          skeittivideot. Tällä hetkellä videoita löytyy <b>{{$store.state.titles.length || "-"}}</b> kappaletta.
        </p>
        <h2>Sivuston toiminta</h2>
        <p>
          Arkistoon voi lisätä videoita Vimeosta tai Youtubesta. Näiden
          palveluiden videot ovat lisensoitu siten, että julkaisijan lupaa ei
          tarvita sivustolle upotukseen. Kuka tahansa voi lisätä videoita
          sivulle täyttämällä sitä varten tehdyn
          <a href="/lisaavideo">lomakkeen</a>. Skeittimediasta ja muusta
          byrokratiasta poiketen, sivustolle ovat kaikki suomalaiset skedeleffat
          tervetulleita. Videot kuitenkin tarkistetaan ylläpitäjien toimesta,
          jotta arkisto ei täyty skeittaukseen liittymättömistä huumori huiskaus
          videoista. Skeittivideon kriteerit ovat yleisöäänestyksen perusteella
          valittuja ja ne löytyvät
          <a href="/lisaavideo">videon lisäys sivulta</a>.
        </p>
        <h2>Ylläpitäjäksi?</h2>
        <p>
          Tässä kohtaa on varmaan jo selvää, että videoiden tarkastuksessa on
          iso työ. Sivuston tekijällä on vähän turhan monta projektia aina
          käynnissä, joten arkiston toiminta riippuu vapaaehtoisista
          ylläpitäjistä. Ylläpitäjät tarkistavat uudet videot sekä voivat
          muokata aiempien videoiden tietoja paremmiksi. Sivusto toimii ilman
          tuottoa, joten ylläpitäjiä ei voida palkita rahallisesti. Halutessasi
          saat kuitenkin muutamia omien skedevideoiden markkinointiin liittyviä
          pikku bonareita:
        </p>
        <ul>
          <li>
            Pääset halutessasi <a href="/adminit">ylläpitäjät sivulle</a> Instagram profiilisi ja
            arkiston suosikkileffan kanssa
          </li>
          <li>Saat suosikkivideosi korostettuna sivuston etusivulle.</li>
        </ul>
        <p>
          Kiinnostuitko? laita viestiä osoitteeseen jerry.selin@gmail.com tai
          instagramissa
          <a href="https://www.instagram.com/jerryselin/">@jerryselin</a>.
        </p>
      </div>
    </div>
    <div style="flex-grow:1"></div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import Footer from "../components/Footer.vue";
import Navigation from "../components/Navigation.vue";
export default {
  name: "AboutPage",
  components: {
    Logo,
    Footer,
    Navigation
  },
  props: {},
  methods: {},
  created: function () {},
  data() {
    return {};
  },
              metaInfo() {
        return { 
            title: "Tietoa arkistosta - Skeittivideot.fi",
            meta: [
                { name: 'description', content:  "Skeittivideot.fi toimii vapaaehtoisvoimin. Kaikki saavat lisätä sivuille uusia videoita, ylläpito tarkastaa uusien videoiden tiedot ennen julkaisua."},
                { property: 'og:title', content: "Tietoa arkistosta - Skeittivideot.fi"},
                { property: 'og:site_name', content: 'Skeittivideot.fi'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
  margin: auto;
  text-align: left;
  width: 600px;
  max-width: 95%;
}
h1, h2, h3, h4{
  text-align: center;
}

</style>
