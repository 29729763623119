<template>
  <div :class="'collapsible-main' + (textOpen ? 'open' : '')">
    <div
      class="title-row noselect"
      @click="handleClick"
      :style="{
        cursor: fixed ? 'initial' : 'pointer',
      }"
    >
      <h2>{{ $props.title }}</h2>
      <span
        v-if="!fixed"
        :style="{
          transform: 'rotate(' + (textOpen ? '0' : '180') + 'deg)',
          top: (textOpen ? '4' : '0') + 'px',
        }"
        class="collapse-icon"
        >&#x25b2;</span
      >
    </div>
    <div v-if="!textOpen" class="preview">
      <p v-html="previewText"></p>
    </div>
    <div v-if="textOpen" class="content">
      <p v-html="$props.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Collapsible",
  components: {},
  props: {
    text: String,
    title: String,
  },
  watch: {
    text: function (newVal) {
      // watch it

      let textOpen = false;
      let fixed = false;
      let previewText = newVal.substring(0, 100);
      if (newVal.length < 101) {
        textOpen = true;
        fixed = true;
      }
      this.textOpen = textOpen;
      this.fixed = fixed;
      this.previewText = previewText + "…";
    },
  },
  methods: {
    handleClick() {
      if (!this.fixed) {
        this.textOpen = !this.textOpen;
      }
    },
  },
  data() {
    let textOpen = false;
    let fixed = false;
    let previewText = this.$props.text.substring(0, 100) + "…";

    if (this.$props.text.length < 101) {
      textOpen = true;
      fixed = true;
    }

    return {
      textOpen,
      fixed,
      previewText,
      collapseIconStyle: {
        transform: "rotate(" + (textOpen ? "180" : "0") + "deg)",
        top: "0px",
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  font-size: 22px;
}
.title-row {
  display: flex;
  justify-content: center;
}

.collapse-icon {
  margin-left: 10px;
  position: relative;
  top: 0px;
  font-size: 30px;
}
.preview {
  height: 45px;
  word-wrap: ellipsis;

  box-shadow: 0 5px 5px 0px rgb(233, 233, 233);
}
.preview * {
  max-height: 45px;
  overflow: hidden;
}
.preview *,
.content * {
  margin-top: 0px;
  margin-bottom: 4px;
}
.collapsible-main {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 10%,
    rgb(233, 233, 233) 100%
  );
}
.collapsible-main .open {
  background: transparent;
}
</style>
