<template>
  <div class="page">
    <Logo />
    <Navigation />
    <h1>{{ "Muokattava video" }}</h1>
    <AdminVideoEditor v-if="oldVideo && oldVideo.title" v-on:accept-edit="acceptEdit" v-on:decline-edit="decline" :videoToEdit="oldVideo" />
    <div style="flex-grow: 1"></div>
    <Footer />
  </div>
</template>

<script>
import firebase from "firebase/app";
import Logo from "../components/Logo.vue";
import Footer from "../components/Footer.vue";
import Navigation from "../components/Navigation.vue";

import AdminVideoEditor from "../components/AdminVideoEditor.vue";

export default {
  name: "EditVideoPage",
  components: {
    Logo,
    Navigation,
    AdminVideoEditor,
    Footer,
  },
  props: {
  },
  methods: {

    acceptEdit(video){
      this.video = video;
      this.accept();
    },
    handleAdminRoute() {
      if (!this.$store.getters.admin) {
        if (localStorage.getItem("usedToAdmin")) {
          this.$router.replace("/admin");
        } else {
          this.$router.replace("/");
        }
        return false;
      }
      return true;
    },
    validateParams() {
      if (
        !this.$route.params.id ||
        this.$route.params.id.length < 5 ||
        this.$route.params.id.length > 30
      ) {
        this.$router.replace({ path: "/404" });
      }
    },

    async accept() {
      this.addLowFields();

      if (this.video.artists_low && this.video.artists_low.length) {
        await this.updateSearch("artists", this.video.artists_low);
      }
      if (this.video.skaters_low && this.video.skaters_low.length) {
        await this.updateSearch("skaters", this.video.skaters_low);
      }
      await this.updateSearch("titles", [this.video.title]);

      await this.updateSearch("creators", [this.video.creator_low]);

      let query = await this.db
        .collection("videos")
        .where("videoId", "==", this.$route.params.id).get()
        const thing = query.docs[0];
        await thing.ref.update(this.video);

      this.$router.replace({ path: "/videot/" + this.oldVideo.videoId });
    },
    addLowFields() {
      let names = [];
      let artists_low = [];
      let skaters_low = [];

      if (this.video.songs && this.video.songs.length > 0) {
        for (let song of this.video.songs) {
          const artist = song.split(" - ")[0].trim().toLowerCase();
          artists_low.push(artist);
          names.push(artist);
        }
      }
      if (this.video.skaters && this.video.skaters.length > 0) {
        for (let skater of this.video.skaters) {
          names.push(skater.trim().toLowerCase());
          skaters_low.push(skater.trim().toLowerCase());
        }
      }
      this.video.names = names;
      this.video.skaters_low = skaters_low;
      this.video.artists_low = artists_low;
      this.video.creator_low = this.video.creator
        .trim()
        .replace(/\s\s+/g, " ")
        .toLowerCase();
      this.video.creator = this.video.creator.trim().replace(/\s\s+/g, " ");
    },
    async updateSearch(document, data) {
      let myDoc = this.db.collection("search").doc(document);
      let fetchedData = await myDoc.get();
      let fetchedList = fetchedData.data().list;
      if (!data || !data.length) {
        return;
      }
      for (const dataItem of data) {
        if (!fetchedList.includes(dataItem)) {
          fetchedList.push(dataItem);
        }
      }
      await this.db;
      myDoc.set({ list: fetchedList });
    },
    async decline() {
      if(!confirm("Poistetaanko video kokonaan?")){
        return
      }
      this.db.collection("videos").where("videoId", "==", this.$route.params.id).get().then((result) => {
          if(result.docs.length){

            console.log(result.docs[0].id)

            let fbId = result.docs[0].id

            this.db.collection("videos").doc(fbId).delete()
          }

        });
      this.$router.replace({ path: "/" });
      
    },

    fetchVideo() {
      this.db
        .collection("videos")
        .where("videoId", "==", this.$route.params.id)
        .get()
        .then((result) => {
          if(result.docs.length){
            this.oldVideo = result.docs[0].data();
          }

        });
    },
  },
  created: function () {
    if (!this.handleAdminRoute()) {
      return;
    }
    this.validateParams();
    this.db = firebase.firestore();
    this.fetchVideo();
  },
  data() {
    return {
      db: undefined,
      video: {},
      oldVideo: {}
    };
  },
  metaInfo() {
    return {
      title: "Muokkaa videoa - Skeittivideot.fi",
      meta: [
        { name: "description", content: "" },
        { property: "og:title", content: "" },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
