//import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
var firebaseConfig = {
  apiKey: "AIzaSyByhOOz0XOPCI4655wVMk6_XTIqq-KNpjQ",
  authDomain: "skedevideot.firebaseapp.com",
  databaseURL: "https://skedevideot.firebaseio.com",
  projectId: "skedevideot",
  storageBucket: "skedevideot.appspot.com",
  messagingSenderId: "1027111390231",
  appId: "1:1027111390231:web:207cf33d879379d3bfa052"
};
export default firebaseConfig 
