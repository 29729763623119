import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './routes'
//import firebaseConfig from './firebaseConfig'
import firebase from 'firebase/app'
import infiniteScroll from 'vue-infinite-scroll'
import store from './store.js'
import VueMeta from 'vue-meta'
import wb from "./registerServiceWorker";
import Toastify from 'toastify-js'
Vue.prototype.$workbox = wb;
Vue.prototype.$toast =  (text, time) => {
  Toastify({
    text: text,
    gravity: "bottom",
    duration: time || 5000,
    position: "center",
    }).showToast();
};
Vue.prototype.$errorToast =  (text, time) => {
  Toastify({
    text: text,
    gravity: "bottom",
    duration: time || 5000,
    position: "center",
    backgroundColor: "linear-gradient(to right, #db5a5a, #992828)",
    }).showToast();
};

let config;
if (process.env.NODE_ENV === 'production') {
  config = require('./prodFirebaseConfig.js').default
} else {
  config = require('./devFirebaseConfig.js').default
}

firebase.initializeApp(config)
const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});


Vue.use(infiniteScroll)
Vue.use(VueRouter);
Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(infiniteScroll)
// eslint-disable-next-line
let vue = new Vue({
  
  render: h => h(App),
  router: router,
  store

}).$mount('#app')

