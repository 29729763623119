<template>
  <div class="page">
    <Logo />
    <Navigation />
    <div class="page-container">
      <h1>{{ "Videon lisäys arkistoon" }}</h1>
      <p>
        Kokoelmaan kelpaa kaikki suomiskedeleffat taitotasosta riippumatta.
        <br />
        Videoiden tarvitsee olla ladattuna Vimeoon tai Youtubeen.
        <br />
        Lisäämisen jälkeen ylläpito tarkistaa videon ja lisää sen julkiseksi
        sivuille.
      </p>

      <div class="instruction-container container">
        <div class="row pointer" v-on:click="toggleInstructions()">
          <h2>Arkistoitujen videoiden kriteerit</h2>
          <img
            v-if="instructionsOpen"
            class="plus-minus"
            src="https://spottimetta.fi/images/miinus.png"
            height="40"
            width="40"
            title="Sulje ohjelaatikko"
            alt="ohjelaatikon sulkeminen - miinus merkki"
          />
          <img
            v-if="!instructionsOpen"
            class="plus-minus"
            src="https://spottimetta.fi/images/plus.png"
            height="40"
            width="40"
            title="Avaa ohjelaatikko"
            alt="ohjelaatikon avaus - plus merkki"
          />
        </div>
        <transition name="fade">
          <div v-if="instructionsOpen" class="instruction-container-inner">
            <p class="text-same-line">
              Videoiden tarvitsee olla
              <strong class="text-same-line">suomalaisia</strong>
              <br />
              Yleisöäänestyksen perusteella valitut suomalaisen videon kriteerit
              ovat:
            </p>
            <ul>
              <li>- Yli puolet porukasta suomalaisia</li>
              <li>- Yli puolet matskuista kuvattu suomessa</li>
            </ul>
            <br />
            <p>
              Laadun takaamiseksi vaadimme videoilta myös seuraavat kriteerit
            </p>
            <ul>
              <li>- Yli puolet matskuista kuvattu spoteilla</li>
              <li>
                -
                {{
                  "Video luokitellaan johonkin seuraavista kategorioista: " +
                  categories.join(", ")
                }}
              </li>
              <li>- Partin pituus yli minuutti</li>
              <li>- Leffan pituus yli 5 minuuttia</li>
              <li>- Muiden videoiden pituus pituus yli 2 minuuttia</li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="container">
        <h2>1. Tarkista löytyykö kyseinen video jo arkistosta</h2>

        <p>
          Ethän lisää erikseen vetäjän parttia, mikäli koko leffa on jo
          saitilla, tätä varten on leffojen haku skeittarin perusteella.
        </p>

        <label for="vidTitle">Videon otsikon haku</label>

        <div class="inline-text-button-div">
          <VSelect
            id="vidTitle"
            class="margin-horizontal"
            :options="$store.state.titles"
          />
        </div>
      </div>

      <div class="container">
        <h2>2. Täytä videon osoite</h2>

        <label for="vidUrl">Videon osoite (Youtube tai Vimeo)</label>

        <div class="inline-text-button-div">
          <input
            id="vidUrl"
            type="text"
            v-model="url"
            v-on:keyup.enter="checkUrl()"
            v-on:input="urlChanged"
            placeholder="videon osoite"
          />
          <button class="inline-button" v-on:click="checkUrl">Tarkista</button>
        </div>
        <span class="warning" v-if="!urlChecked">Tarkistamaton osoite</span>
      </div>
      <transition name="fade">
        <div v-if="urlChecked">
          <div class="container">
            <h2>3. Täytä pakolliset kentät</h2>
            <label for="title">Videon Nimi</label>
            <input
              type="text"
              id="title"
              v-model="title"
              placeholder="Videon nimi"
            />
            <label for="creator">Videon tekijä (merkki tai henkilö)</label>
            <input
              type="text"
              id="creator"
              v-model="creator"
              placeholder="Videon tekijä"
            />
            <label for="location">Videon/possen paikkakunta</label>
            <VSelect
              id="location"
              class="v-select"
              v-model="location"
              :options="cities"
            />

            <label for="category">Videon kategoria</label>
            <VSelect
              id="category"
              class="v-select"
              v-model="category"
              :options="categories"
            />

            <div class="inline-container checkbox-container">
              <input id="company-checkbox" v-model="brand" type="checkbox" />
              <label for="company-checkbox">Skedemerkin leffa</label>
            </div>
            <label for="quality">Kuvanlaatu {{this.fetching ? " (Haetaan...)" : ""}}</label>

            <VSelect
              id="quality"
              class="v-select"
              v-model="qualityStr"
              :options="qualityStrs"
            />

            <label for="releaseYear">Julkaisuvuosi</label>
            <VSelect
              id="releaseYear"
              class="v-select"
              v-model="year"
              :options="releaseYears"
            />
          </div>
          <div class="container">
            <h2>4. Täytä vapaaehtoiset kentät (SUOSITUS!)</h2>
            <label for="comment">Kommentti ylläpidolle</label>
            <input
              id="comment"
              type="text"
              v-model="comment"
              placeholder="Kommentti ylläpidolle"
            />
            <div class="inline-container checkbox-container">
              <input
                id="multipart-checkbox"
                v-model="multipart"
                type="checkbox"
              />
              <label for="multipart-checkbox"
                >Leffa jaettu useaan videoon</label
              >
            </div>
            <div v-if="multipart" class="form-info-box">
              <h2>Lisää edellisen ja seuraavan videon osoite</h2>
              <p>
                Voit lisätä osoitteen Vimeosta, Youtubesta tai Skeittivideot.fi
                sivulta. Seuraavaa ja edellistä videota ehdotetaan kuitenkin
                vasta kun kyseinen video on lisätty skeittivideot.fi sivulle.
              </p>
              <label for="prev-video">Leffan aiempi video</label>
              <div class="row">
                <input
                  id="prev-video"
                  type="text"
                  v-model="prevVideo"
                  placeholder="Edellinen video"
                />
                <div v-if="prevVideo">
                  <img
                    v-if="!validateUrl(prevVideo)"
                    src="@/assets/red-x-line.svg"
                    height="50px"
                    title="Ei kelvollinen osoite"
                    alt="Ei kelvollinen osoite"
                  />
                  <img
                    v-else
                    src="@/assets/green-checkmark-line.svg"
                    height="50px"
                    title="Videon tunniste löydetty osoitteesta"
                    alt="Videon tunniste löydetty osoitteesta"
                  />
                </div>
              </div>

              <label for="next-video">Leffan seuraava video</label>
              <div class="row">
                <input
                  id="next-video"
                  type="text"
                  v-model="nextVideo"
                  placeholder="Seuraava video"
                />
                <div v-if="nextVideo">
                  <img
                    v-if="!validateUrl(nextVideo)"
                    src="@/assets/red-x-line.svg"
                    height="50px"
                    title="Ei kelvollinen osoite"
                    alt="Ei kelvollinen osoite"
                  />
                  <img
                    v-else
                    src="@/assets/green-checkmark-line.svg"
                    height="50px"
                    title="Videon tunniste löydetty osoitteesta"
                    alt="Videon tunniste löydetty osoitteesta"
                  />
                </div>
              </div>
            </div>
            <label for="camera">Kuvauksessa käytetty kamera</label>
            <VSelect
              id="camera"
              class="v-select"
              v-model="camera"
              :options="cameras"
            />

            <label class="tight-row" for="songField"
              >Videon biisit
               {{this.fetching ? " (Haetaan...) " : ""}}
              <a
                href="https://chrome.google.com/webstore/detail/aha-music-song-finder-for/dpacanjfikmhoddligfbehkpomnbgblf"
                >biisin tunnistaja</a
              ></label
            >
            <div class="inline-text-button-div">
              <input
                v-on:keyup.enter="addSong()"
                id="songField"
                class="inline-input-text"
                v-model="song"
                type="text"
                placeholder="Artisti - biisi"
              />
              <button class="inline-button" v-on:click="addSong()">
                Lisää biisi
              </button>
            </div>
            <ul class="listing-ul">
              <li
                v-for="listedSong in songs"
                class="listing"
                :title="'Poista biisi (' + listedSong + ') listasta'"
                :key="listedSong"
              >
                <button
                  :id="'song-' + listedSong"
                  v-on:click="removeSong(listedSong)"
                >
                  X
                </button>
                <label :for="'song-' + listedSong">{{ listedSong }}</label>
              </li>
            </ul>
            <div class="same-line left">
              <label for="skaters" class="text-same-line"
                >Videon skeittarit</label
              >
              <span @click="handleCopyPaste()" class="text-same-line link m20l"
                >Avaa Copy-Paste lisäys</span
              >
            </div>
            <div class="inline-text-button-div">
              <input
                v-on:keyup.enter="addSkater()"
                id="skaters"
                v-model="skater"
                type="text"
                placeholder="Kirjoita skeittarin nimi"
              />
              <button class="inline-button" v-on:click="addSkater()">
                Lisää skeittari
              </button>
            </div>
            <ul class="listing-ul">
              <li
                v-for="listedSkater in skaters"
                class="listing"
                :key="listedSkater"
                :title="'Poista skeittari (' + listedSkater + ') listasta'"
              >
                <button
                  :id="'skater-' + listedSkater"
                  v-on:click="removeSkater(listedSkater)"
                >
                  X
                </button>
                <label :for="'skater-' + listedSkater">{{
                  listedSkater
                }}</label>
              </li>
            </ul>

            <label for="description"
              >Kuvaus (Älä kopioi Youtubesta tai Vimeosta, ne haetaan
              automaattisesti)</label
            >
            <input
              id="description"
              v-model="description"
              type="text"
              placeholder="Kuvaus"
            />
          </div>
          <div class="container">
            <h2>5. Lähetä ylläpidon tarkastukseen</h2>
            <button class="submit" v-on:click="handleSubmit()">Lähetä</button>
          </div>
        </div>
      </transition>
    </div>
    <div style="flex-grow: 1"></div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Logo from "../components/Logo.vue";
import Navigation from "../components/Navigation.vue";
import Footer from "../components/Footer.vue";
import { brandList, brandListLow, brandToCity } from "../skateBrands.js";
import firebase from "firebase/app";
import VSelect from "vue-select";
import categories from "../categories.js";
import cameras from "../cameras.js";
import cities from "../cities.js";
import {
  qualityStrs,
  qualityNums,
  qualityNumToStr,
  qualityStrToNum,
} from "../videoQualities";

export default {
  name: "AddVideoPage",
  components: {
    VSelect,
    Logo,
    Footer,
    Navigation,
  },

  methods: {
    changeQuality() {
      this.quality = this.qualityStrToNum[this.qualityStr];
    },
    handleCopyPaste() {
      const skatersStr = prompt(
        "Liitä lista skeittareista tähän (esim. videon kuvauksesta)."
      );
      if (!skatersStr) {
        return;
      }
      const separator = prompt(
        "Liitä listan erottimena käytetty merkki, jätä tyhjäksi, mikäli kyseessä on rivinvaihto.\n\nAiemmin liitetty lista:\n" +
          skatersStr
      );
      let skatersList = [];
      if (separator) {
        skatersList = skatersStr.split(separator);
      } else if (separator !== null) {
        skatersList = skatersStr.split("\n");
      }
      for (let i = 0; i < skatersList.length; i++) {
        skatersList[i] = skatersList[i].trim();
      }

      if (skatersList.length > 1) {
        this.skaters = this.skaters.concat(skatersList);
      }
      this.skaters = this.skaters.filter((item, pos) => {
        return this.skaters.indexOf(item) == pos;
      });
    },
    toggleInstructions() {
      this.instructionsOpen = !this.instructionsOpen;
    },

    removeSong(song) {
      let index = this.songs.indexOf(song);
      this.songs.splice(index, 1);
    },
    removeSkater(skater) {
      let index = this.skaters.indexOf(skater);
      this.skaters.splice(index, 1);
    },

    async checkUrl() {
      if (this.url.includes("vimeo")) {
        let regexArr = this.url.match(/(videos|video|channels|\.com)\/([\d]+)/);
        if (regexArr && regexArr.length > 2) {
          let videoId = regexArr[2];
          if (await this.checkAlreadyOnSite(videoId)) {
            this.$errorToast("Video on jo arkistossa");
            return;
          }
          this.getVimeoData(videoId);
        }
      } else if (this.url.includes("youtu")) {
        let regexArr = this.url.match(/(\/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)/);
        if (regexArr && regexArr.length > 2) {
          let videoId = regexArr[2];
          if (await this.checkAlreadyOnSite(videoId)) {
            this.$errorToast("Video on jo arkistossa");
            return;
          }
          this.getYoutubeData(videoId);
        }
      }
      

    },
    validateUrl(url) {
      if (url.includes("vimeo")) {
        let regexArr = url.match(/(videos|video|channels|\.com)\/([\d]+)/);
        if (
          regexArr &&
          regexArr.length > 2 &&
          regexArr[2].length > 6 &&
          regexArr[2].length < 10
        ) {
          return true;
        }
      } else if (url.includes("youtu")) {
        let regexArr = url.match(/(\/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)/);
        if (regexArr && regexArr.length > 2 && regexArr[2].length > 5) {
          return true;
        }
      }
      return false;
    },
    // Take quess of the video location based on the username
    async quessLocation() {
      let query =this.db.collection("videos").where("creator_low", "==", this.creator.toLowerCase())//.orderBy("year", "desc")
        .limit(1)
      let docs = (await query.get()).docs
      if(docs.length){
        this.location = docs[0].data().location
      }
    },
    parseRelativeVideo(url) {
      if (url.includes("vimeo")) {
        let regexArr = url.match(/(videos|video|channels|\.com)\/([\d]+)/);
        if (
          regexArr &&
          regexArr.length > 2 &&
          regexArr[2].length > 6 &&
          regexArr[2].length < 10
        ) {
          return { provider: "vimeo", videoId: regexArr[2] };
        }
      } else if (url.includes("youtu")) {
        let regexArr = url.match(/(\/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)/);
        if (regexArr && regexArr.length > 2 && regexArr[2].length > 5) {
          return { provider: "youtube", videoId: regexArr[2] };
        }
      }
      return null;
    },

    urlChanged() {
      this.urlChecked = false;
      this.title = "";
      this.providerDescription = "";
      this.creator = "";
      this.category = "";
      this.camera = "Ei tiedossa";
      this.location = "";
      this.songs = [];
      this.qualityStr = "";
      this.song = "";
      this.videoId = "";
      this.skaters = [];
      this.skater = "";
      this.description = "";
      this.provider = "";
      this.comment = "";
      this.duration = 0;
      this.quality = 0;
      this.year = 2020;
      this.brand = false;
      this.multipart = false;
      this.prevVideo = "";
      this.nextVideo = "";
    },
    addSong() {
      if (!this.song.includes(" - ")) {
        this.$errorToast(
          "Kirjoita leffan musiikit yksi kappale kerrallaan muodossa <br/> Artisti - Biisi"
        );
        return;
      }
      let trimmedsong = this.song.trim().replace(/\s\s+/g, " ");
      if (this.songs.includes(trimmedsong)) {
        this.$errorToast("Kyseinen biisi on jo listattuna");
        return;
      }
      this.songs.push(trimmedsong);
      this.song = "";
    },
    addSkater() {
      if (!this.skater || !this.skater.length) {
        return;
      }
      let trimmedskater = this.skater.trim().replace(/\s\s+/g, " ");
      if (this.skaters.includes(trimmedskater)) {
        this.$errorToast("Kyseinen skeittari on jo listattuna");
        return;
      }
      this.skaters.push(trimmedskater);
      this.skater = "";
    },
    async checkAlreadyOnSite(videoId) {
      let ref = this.db.collection("videos").where("videoId", "==", videoId);
      let result = await ref.get();
      return !result.empty;
    },
    async handleSubmit() {
      if (!this.checkEmptyFields()) {
        return;
      }
      if (!this.checkLength()) {
        this.$errorToast("Video on liian lyhyt kategoriaan " + this.category);
        return;
      }

      let data = {
        title: this.title,
        creator: this.creator,
        category: this.category,
        provider: this.provider,
        location: this.location,
        videoId: this.videoId,
        year: parseInt(this.year),
        duration: this.duration,
        quality: this.qualityStrToNum[this.qualityStr],
        dateAdded: new Date().getTime(),
        brand: this.brand,
        comment: this.comment,
        providerDescription: this.providerDescription,
      };
      if (this.comment && this.comment.length) {
        data.comment = this.comment;
      }
      if (this.camera && this.camera.length && this.camera !== "Ei tiedossa") {
        data.camera = this.camera;
      }
      if (this.songs && this.songs.length > 0) {
        data.songs = this.songs;
      }
      if (this.skaters && this.skaters.length > 0) {
        data.skaters = this.skaters;
      }

      if (this.description && this.description.length > 0) {
        data.description = this.description;
      }
      if (this.multipart) {
        if (this.prevVideo) {
          data.prevVideo = this.parseRelativeVideo(this.prevVideo);
          data.multipart = true;
        }
        if (this.nextVideo) {
          data.nextVideo = this.parseRelativeVideo(this.nextVideo);
          data.multipart = true;
        }
      }

      this.addVideoToFirestore(data);
      this.url = "";
      this.urlChanged();
    },

    addVideoToFirestore(data) {
      this.db
        .collection("uncheckedVideos")
        .add(data)
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          alert("Videon lähetyksessä tapahtui virhe...");
          console.log(error);
        });
    },
    checkEmptyFields() {
      if (!this.title.length) {
        this.$errorToast("Videon otsikko puuttuu");
        return false;
      }
      if (!this.creator) {
        this.$errorToast("Videon tekijä puuttuu");
        return false;
      }
      if (this.category < 3) {
        this.$errorToast("Videon kategoria puuttuu");
        return false;
      }
      if (this.location < 2) {
        this.$errorToast("Videon sijainti puuttuu");
        return false;
      }
      if (!this.provider) {
        this.$errorToast("Videon haussa häikkää");
        return false;
      }
      if (!this.duration) {
        this.$errorToast("Videon haussa häikkää");
        return false;
      }
      if (!this.year) {
        this.$errorToast("Videon vuosi puuttuu");
        return false;
      }
      if (!this.qualityStr) {
        this.$errorToast("Videon kuvanlaatu puuttuu");
        return false;
      }
      return true;
    },
    checkLength() {
      switch (this.category) {
        case "Leffa":
          return this.duration > 5 * 60;
        case "Partti":
          return this.duration > 1 * 60;
        default:
          return this.duration > 2 * 60;
      }
    },

    getVimeoData(videoId) {
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          let resObj = JSON.parse(xmlHttp.responseText);
          if (resObj && resObj[0] && resObj[0].id && resObj[0].duration) {
            this.duration = resObj[0].duration;
            if (this.duration > 600) {
              this.category = "Leffa";
            }
            let quality = resObj[0].height
            if(quality && this.qualityNums.includes(quality)){
              this.quality = quality
              this.qualityStr = this.qualityNumToStr[quality]
            }

            this.title = resObj[0].title;
            this.year = resObj[0].upload_date.split("-")[0];
            
            this.creator = resObj[0].user_name;
            this.providerDescription = resObj[0].description;
            let lowTitle = resObj[0].title.toLowerCase();
            let lowCreator = resObj[0].user_name.toLowerCase();
            this.provider = "vimeo";
            this.videoId = videoId;
            for (let i = 0; i < brandListLow.length; i++) {
              if (
                lowTitle.includes(brandListLow[i]) ||
                lowCreator.includes(brandListLow[i])
              ) {
                const brand = brandList[i];
                this.creator = brand;
                this.brand = true;

                this.location = brandToCity[brand];
                if (this.title.includes(brand + " - ")) {
                  this.title = this.title.replace(brand + " - ", "");
                }
              }
            }
            
          }
          if(this.location === ""){
            this.quessLocation()
          }
          
          this.urlChecked = true;
        }
      };
      let url = "https://vimeo.com/api/v2/video/" + videoId + ".json";
      xmlHttp.open("GET", url, true); // true for asynchronous
      xmlHttp.send(null);
    },

    getYoutubeData(videoId) {
      this.handleGetSongs(videoId);
      // Send request for video length
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          let resObj = JSON.parse(xmlHttp.responseText);
          if (
            resObj &&
            resObj.items &&
            resObj.items[0] &&
            resObj.items[0].contentDetails &&
            resObj.items[0].snippet
          ) {
            let details = resObj.items[0].contentDetails;
            let snippet = resObj.items[0].snippet;
            this.duration = this.parseDurationString(details.duration);
            if (this.duration > 600) {
              this.category = "Leffa";
            }
            
            //this.quality = details.definition === "hd" ? 1080 : 540;
            this.title = snippet.title;
            this.year = snippet.publishedAt.split("-")[0];
            this.creator = snippet.channelTitle;
            let lowTitle = snippet.title.toLowerCase();
            let lowCreator = snippet.channelTitle.toLowerCase();
            this.provider = "youtube";
            this.providerDescription = snippet.description.replace(
              /\n/g,
              "<br />"
            );
            this.videoId = videoId;
            for (let i = 0; i < brandListLow.length; i++) {
              if (
                lowTitle.includes(brandListLow[i]) ||
                lowCreator.includes(brandListLow[i])
              ) {
                const brand = brandList[i];
                this.creator = brand;
                this.brand = true;
                this.location = brandToCity[brand];
                if (this.title.includes(brand + " - ")) {
                  this.title = this.title.replace(brand + " - ", "");
                }
              }
            }
            if(this.location === ""){
              this.quessLocation()
            }
            this.urlChecked = true;
          }
        }
      };
      let url =
        "https://www.googleapis.com/youtube/v3/videos?id=" +
        videoId +
        "&part=contentDetails&part=id%2C+snippet&key=AIzaSyA6g-YHgW8lnqLwSnNu28sBap4XqVUeGMk";
      xmlHttp.open("GET", url, true); // true for asynchronous
      xmlHttp.send(null);
    },

    parseDurationString(durationString) {
      let stringPattern = /^PT(?:(\d+)D)?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d{1,3})?)S)?$/;
      let stringParts = stringPattern.exec(durationString);
      return (
        (((stringParts[1] === undefined ? 0 : stringParts[1] * 1) /* Days */ *
          24 +
          (stringParts[2] === undefined ? 0 : stringParts[2] * 1)) /* Hours */ *
          60 +
          (stringParts[3] === undefined
            ? 0
            : stringParts[3] * 1)) /* Minutes */ *
          60 +
        (stringParts[4] === undefined ? 0 : stringParts[4] * 1) /* Seconds */
      );
    },
    async handleGetSongs(id) {
      this.fetching = true
      fetch("https://ytsongs-rttg5v3iwq-lz.a.run.app/" + id, {mode:'cors'})
        .then(response => response.json())
        .then(myJson => {
          this.fetching = false
          let music = myJson.music
          let autoQuality = myJson.quality
          if(music && music.length){
            for(let song of music){
              let trimmedsong = song.trim().replace(/\s\s+/g, " ");
              if (this.songs.includes(trimmedsong)) {
                continue
              }
              this.songs.push(trimmedsong);
              }
          }
          if(autoQuality && !isNaN(autoQuality) && autoQuality > 0){
            this.quality = autoQuality
            this.qualityStr = qualityNumToStr[autoQuality]
          }
        });
    },
  },

  created: function () {
    this.db = firebase.firestore();
  },

  data() {
    let releaseYears = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1990; i <= currentYear; i++) {
      releaseYears.push(i);
    }

    return {
      cities,
      urlChecked: false,
      categories,
      qualityStrs,
      qualityStr: "",
      qualityNumToStr,
      qualityNums,
      releaseYears,
      cameras,
      qualityStrToNum,
      url: "",
      title: "",
      providerDescription: "",
      creator: "",
      category: "",
      camera: "Ei tiedossa",
      location: "",
      song: "",
      songs: [],
      provider: "",
      skaters: [],
      skater: "",
      description: "",
      videoId: "",
      comment: "",
      quality: 0,
      duration: 0,
      year: 2020,
      brand: false,
      fetching: false,
      instructionsOpen: false,
      multipart: false,
      prevVideo: "",
      nextVideo: "",
    };
  },
  metaInfo() {
    return {
      title: "Videon lisäys - Skeittivideot.fi",
      meta: [
        {
          name: "description",
          content:
            "Lisää video skedeleffaarkistoon. Tarkista suomalaisen skeittivideon vaatimukset ja linkitä pätkä Youtubesta tai Vimeosta.",
        },
        { property: "og:title", content: "Videon lisäys - Skeittivideot.fi" },
        { property: "og:site_name", content: "Skeittivideot.fi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-container * {
  margin: auto;
  display: block;
}
.inline-container * {
  display: inline-block;
  width: max-content;
  text-align: left;
}
.inline-text-button-div {
  display: flex;
}
.inline-text-button-div * {
  align-items: center;
  max-width: 70%;
}
.inline-button {
  flex: 2 1 auto;
  width: auto;
  margin-left: 10px;
  text-align: center;
}

.container {
  background-color: rgb(239, 242, 255);
  width: fit-content;
  padding: 20px;
  border-radius: 10px;
  border-color: silver;
  border: 1px solid;
  width: 90vw;
  margin: auto;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.instruction-container-inner {
  margin: 10px;
  text-align: left;
}

.row {
  display: flex;
  justify-content: center;
}
.text-same-line {
  display: inline;
}
.plus-minus {
  margin: auto;
  cursor: pointer;
}
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
button {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
label {
  margin-top: 10px !important;
  text-align: left;
}
.form-info-box {
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 20px;
  padding: 10px;
}
.form-info-box h2 {
  font-size: 22px;
}
.checkbox-container {
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.checkbox-container label {
  padding-left: 10px;
}
.checkbox-container * {
  cursor: pointer;
}
.warning {
  color: red;
  font-size: 20px;
}
.v-select {
  background-color: white;
}
.listing {
  padding-left: 5px;
  margin-left: 5px;
  height: 30px;
  display: flex;
  width: fit-content;
  justify-content: flex-start;
}
.listing-ul {
  width: fit-content;
  margin-bottom: 30px;
  margin-top: -10px;
  padding-left: 5px;
  margin-left: 5px;
  justify-content: flex-start;
  justify-items: start;
}
.listing * {
  cursor: pointer;
}
.listing button {
  background: none;
  color: red;
  width: 20px;
  position: relative;
  bottom: 7px;
  font-size: 20px;
}
.submit {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 50%;
  min-width: fit-content;
}

.tight-row * {
  display: inline-block;
}

.v-select {
  width: 70%;
  margin-left: 0px;
}
.left {
  text-align: left;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.m20l {
  margin-left: 20px;
}
</style>
